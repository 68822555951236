.blog-list {
  .wrapper {
    .section-banner {
      position: relative;
      height: 306px;
      background: #e31e261a;
      display: flex;
      justify-content: center;
      align-items: center;
      .bg-image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
      .bg-left {
        position: absolute;
        left: 0;
        top: 0;
        height: 50%;
        z-index: -1;
      }
      .bg-right {
        position: absolute;
        right: 0;
        height: 50%;
        bottom: 0;
        z-index: -1;
      }
      .content-wrapper {
        padding: 0 20px;
        max-width: 700px;
        width: 100%;
        text-align: center;
        h1 {
          font-size: 48px;
          font-weight: 700;
          line-height: 48px;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 19.07px;
          color: #e31e26;
        }
      }
    }
    .section-blog-list {
      margin: 50px;
      @media (max-width: 600px) {
        margin: 50px 20px;
      }
      .filter {
        display: flex;
        // flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 30px;
        .select-wrapper {
          width: min(300px, 100%);
          border: 1px solid #cecece;
          border-radius: 20px;
          padding: 0 16px;
          height: 42px;

          select {
            border-radius: 20px;
            width: 100%;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            color: #8a8383;
            border: none;
          }
          select:focus-visible {
            outline: none;
            border-color: transparent;
          }
        }
        .input-wrapper {
          width: min(300px, 100%);
          border-radius: 20px;
          display: flex;
          height: 42px;

          input {
            border: 1px solid #cecece;

            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            color: #8a8383;
            padding: 0 16px;
            border-radius: 20px 0px 0px 20px;
            width: 100%;
          }
          input:focus-visible {
            outline: none;
            // border-color: transparent;
          }
          .search-icon {
            cursor: pointer;
            background: #e31e26;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 20px 20px 0px;
          }
        }
        @media (max-width: 500px) {
          flex-direction: column;
          .select-wrapper,
          .input-wrapper {
            width: 100%;
          }
        }
      }
      .blogs {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
        @media (max-width: 650px) {
          gap: 20px;
        }
      }
    }
  }
}
