.blog-card {
  max-width: 328px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  box-shadow: 0px 3px 11px 0px #0000001a;
  border-radius: 24px;

  .left {
    width: 100%;
    height: 175px;

    border-radius: 24px 24px 0px 0px;
    img {
      border-radius: 24px 24px 0px 0px;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .right {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
      color: #202020;
    }
    .author-detail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .name {
        font-size: 12px;
        font-weight: 700;
        line-height: 16.34px;
        color: #e31e26;
      }
      .publish-detail {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .date,
        .time {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.34px;
          color: #202020;
          display: flex;
          gap: 10px;
          align-items: center;
          svg {
            fill: #e31e26;
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    max-width: 650px;
  }
}
@media (max-width: 500px) {
  .blog-card {
    .left{
      height: 200px;
    }
    .right{
      padding: 10px;
      .title{
        font-size: 14px;
      }
    }
  }
}
