@import "/src/variables";
@import "/src/index.scss";

.home-main {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 105.1px);
  position: relative;
  overflow-x: hidden;

  .home-backdrop {
    background-color: #00000000;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
  }

  p {
    margin: 0;
  }
  .nav-chat-container {
    width: calc(100% - 350px);
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 600px) {
      padding: 20px;
      padding-bottom: 50px;
    }
  }
  .chat-box {
    padding: 20px;
    height: calc(100vh - 85px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: min(80%, 1000px);

    .not-active-chat {
      margin-top: 300px;
      color: $--secondary-font-color;
      padding: 0 60px;
      text-align: center;
    }
  }
  .not-active-chat-box {
    justify-content: unset;
  }
}

@media (max-width: 1300px) {
  .home-main {
    .chat-box {
      width: min(80%, 1000px);
    }
  }
}

@media (max-width: 1150px) {
  .home-main {
    .home-backdrop {
      display: block;
    }
    .nav-chat-container {
      width: 100%;
    }
    .chat-box {
      width: 100%;
      padding: 10px;
    }
  }
}
