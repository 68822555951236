@import "/src/variables";
@import "/src/index.scss";
.busisness-expansion-usa {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;

  .section {
    max-width: 1440px;
    margin: auto;
    .section-container {
      .section-heading {
        color: $--my-black;
        text-transform: capitalize;
        font-weight: 400;
        position: relative;
        margin-bottom: 0;
        p {
          margin-bottom: 0;
        }
        .vertical-text {
          position: absolute;
          color: $--my-red;
          font-weight: 600;
          transform: rotate(-90deg);
        }
      }
      .loading-section-heading {
        height: 80px;
        width: min(100%, 400px);
        margin-bottom: 20px;
      }
      .animate {
        animation: typeWriter 2s;
        animation-name: typeWriter;
        animation-duration: 1.5s;
        animation-delay: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        display: inline-block;
        width: 0%;
        overflow: hidden;
        border-right: 1px solid $--my-red;
      }
      @keyframes typeWriter {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
  .banner {
    position: relative;
    margin-bottom: 60px;

    .bg-image {
      display: flex;
      justify-content: center;
      img {
        // width: 100%;
        height: 600px;
        object-fit: cover;
        height: 580px;
      }
    }
    .verified-logo {
      position: absolute;
      top: 20px;
      left: 50px;
      img {
        width: 80px;
      }
    }
    .content {
      position: absolute;
      color: #ffffff;
      bottom: 60px;
      left: 50px;
      width: 800px;
      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
      }
      h2 {
        font-size: 42px;
        font-weight: 600;
        line-height: 42.68px;
        margin-bottom: 10px;
      }
    }
    .call-btn-wrapper {
      display: flex;
      justify-content: space-between;
      // width: 420px;
      width: min(100%, 420px);

      z-index: 0;

      .call-btn {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 30px;
        .primary-btn {
          border: 1px solid var(--color-brand-logo-1, #e31e26);
          box-shadow: 0px 0px 4px 1px #e31e26 inset;
          transition: all 0.18s ease-in;
          text-wrap: nowrap;
          &:before {
            transition: all 0.18s ease-in;
          }
        }
        .call-icon {
          background-color: $--my-red;
          padding: 8px;
          display: inline-grid;
          place-items: center;
          border-radius: 50%;
          position: absolute;
          transition: all 0.18s ease-in 0s;
          left: 0;
          z-index: -1;
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .call-btn:hover > .call-icon {
        left: calc(100% + 8px);
      }
      .my-svg {
        position: relative;
        // transform: translate(90%, -50%);
        img {
          // position: absolute;
        }
        .animate-SVG {
          position: absolute;
          left: 0;
          animation: svgAnimation 5.5s ease 0s infinite;
        }
        @keyframes svgAnimation {
          0% {
            transform: rotate(0deg);
          }
          20% {
            transform: rotate(0deg);
          }
          30% {
            transform: rotate(-5deg);
          }
          50% {
            transform: rotate(-5deg);
          }
          60% {
            transform: rotate(-15deg);
          }
          80% {
            transform: rotate(-15deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .section-2 {
    padding: 40px;
    margin-bottom: 20px;
    .row-1 {
      display: flex;
      gap: 40px;
      .left {
        width: 50%;
        max-width: 529px;
        img {
          width: 100%;
        }
      }
      .right {
        width: 50%;
        .header {
          text-align: end;
          margin-bottom: 30px;

          h2 {
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
            margin-bottom: 0;
          }
          h1 {
            color: #202020;
            span {
              color: #e31e26;
              font-size: 40px;
              font-weight: 700;
              line-height: 48px;
              margin-bottom: 0;
            }
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }
  .section-3 {
    padding: 40px;
    margin-bottom: 20px;
    .row-1 {
      display: flex;
      gap: 40px;
      .right {
        width: 50%;
        max-width: 529px;

        img {
          width: 100%;
        }
      }
      .left {
        width: 50%;
        .header {
          text-align: start;
          margin-bottom: 30px;
          h2 {
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
            margin-bottom: 0;
          }
          h1 {
            color: #202020;
            span {
              color: #e31e26;
              font-size: 40px;
              font-weight: 700;
              line-height: 48px;
            }
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          ul {
            li {
              font-size: 16px;
              font-weight: 600;
              line-height: 38px;
              span {
                color: #e31e26;
              }
            }
          }
        }
      }
    }
  }
  .section-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    h1 {
      font-size: 32px;
      font-weight: 400;
      line-height: 43.58px;
      text-align: center;
      margin-bottom: 30px;
      span {
        font-size: 32px;
        font-weight: 700;
        line-height: 43.58px;
        .text-red {
          color: #e31e26;
        }
      }
    }
    .image-wrapper-web {
      display: flex;
      padding: 0px 40px;
      svg {
        width: 100%;
      }
    }
    .image-wrapper-mobile,
    .steps-mobile {
      display: none;
    }
    .call-btn-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 0;
      margin-bottom: 30px;
      margin-top: 20px;

      .call-btn {
        display: flex;
        align-items: center;
        position: relative;
        // margin-top: 30px;
        .primary-btn {
          border: 1px solid var(--color-brand-logo-1, #e31e26);
          box-shadow: 0px 0px 4px 1px #e31e26 inset;
          transition: all 0.18s ease-in;
          text-wrap: nowrap;
          &:before {
            transition: all 0.18s ease-in;
          }
        }
        .call-icon {
          background-color: $--my-red;
          padding: 8px;
          display: inline-grid;
          place-items: center;
          border-radius: 50%;
          position: absolute;
          transition: all 0.18s ease-in 0s;
          left: 0;
          z-index: -1;
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .call-btn:hover > .call-icon {
        left: calc(100% + 8px);
      }
      .my-svg {
        position: relative;
        // transform: translate(90%, -50%);
        img {
          // position: absolute;
        }
        .animate-SVG {
          position: absolute;
          left: 0;
          animation: svgAnimation 5.5s ease 0s infinite;
        }
        @keyframes svgAnimation {
          0% {
            transform: rotate(0deg);
          }
          20% {
            transform: rotate(0deg);
          }
          30% {
            transform: rotate(-5deg);
          }
          50% {
            transform: rotate(-5deg);
          }
          60% {
            transform: rotate(-15deg);
          }
          80% {
            transform: rotate(-15deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
    p {
      padding: 0px 40px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        li {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;

          span {
            color: #e31e26;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
          }
        }
        li::marker {
          color: #e31e26;
        }
      }
    }
  }
  .faq-section {
    width: 75%;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 100px;
    margin-top: 30px;
    .faq-heading {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 43.58px;
      margin-bottom: 40px;

      .highlight {
        font-size: 32px;
        font-weight: 700;
        line-height: 43.58px;

        color: red; // Highlight 'Questions' in red
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .accordion-item {
        border: none; // Optional: Remove accordion item borders
      }

      .accordion-header {
        position: relative; // Ensure proper positioning for the icon
        font-weight: 500;

        // Completely override the default arrow icon
        .accordion-button {
          background: #f1f1f1;
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-radius: 0px !important;
          height: 44px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #202020;
        }
        .accordion-button::after {
          content: "+"; // Default icon
          font-size: 20px;
          background-image: unset !important;
          font-weight: bold;
          color: red;
          margin-left: 10px; // Space between text and icon
          transform: none !important; // Prevent Bootstrap's rotation
          transition: all 0.3s ease; // Smooth transition for icon change
        }

        // When the accordion item is expanded
        .accordion-button:not(.collapsed)::after {
          content: "-"; // Change icon to '-'
          font-size: 20px;
          font-weight: bold;
          color: red;
          background-image: unset !important;
        }
      }

      .accordion-body {
        padding: 15px 20px;
        background-color: #f8f8f8;
        font-size: 14px;
        color: #333;
      }
    }
  }
  .section-7 {
    display: flex;
    justify-content: flex-end;
    // padding: 80px;
    .section-7-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      background: #e31e26;
      .connect-form {
        margin: 40px auto;
        padding: 20px;
        border: 1px solid #ffffff;
        border-radius: 20px;
        width: 65%;
        .form-error {
          color: white !important;
        }
        .section-heading {
          font-size: 32px;
          font-weight: 700;
          line-height: 43.58px;
          letter-spacing: 0.01em;
          color: #ffffff;
          text-align: center;
          margin-bottom: 30px;
        }
        .contact-detail {
          display: flex;
          flex-direction: row;
          padding: 10px;
          gap: 20px;
          .left {
            width: 50%;

            form {
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              input,
              textarea {
                border: 1px solid #ffffff;
                width: Fill (439.28px) px;
                height: 44px;
                padding: 10px;
                gap: 10px;
                border-radius: 4px;
                background: transparent;
                color: #ffffff;
              }
              input::placeholder,
              textarea::placeholder {
                color: #ffffff80;
              }
              input:focus,
              textarea:focus {
                border-color: white;
              }
              .input-grp {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                input {
                  width: 100%;
                }
                .input {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  gap: 10px;
                  label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                  }
                }

                .call-grp {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                    margin-bottom: 10px;
                  }

                  input {
                    border: none;
                    width: 100%;
                  }
                  .input {
                    width: 100%;
                    flex-direction: row;
                    gap: 8px;
                    border: 1px solid #ffffff;
                    border-radius: 4px;
                    padding-left: 10px;
                  }
                  select {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: white;
                    option {
                      color: black;
                    }
                  }
                  .country-code {
                    position: relative;
                    p {
                      margin: 0;
                    }
                    .selected-code {
                      display: flex;
                      gap: 8px;
                      align-items: center;

                      p {
                        color: white;
                        font-size: 16px;
                      }
                      img {
                        height: 16px;
                        width: 16px;
                      }
                    }
                    .options {
                      display: none;
                      position: absolute;
                      color: #c6c6c6;
                      z-index: 2;
                      p {
                        margin: 2px 0;
                        cursor: pointer;
                      }
                    }
                  }
                  .open {
                    .selected-code {
                      img {
                        transform: rotate(180deg);
                      }
                    }
                    .options {
                      display: block;
                    }
                  }
                }
              }
              button {
                border-radius: 4px;
                height: 44px;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #e31e26;
                border: none;
                background: #ffffff;
              }
              button:hover {
                background: rgb(253 234 234);
              }
            }
          }
          .right {
            width: 50%;
            display: flex;
            justify-content: end;
            .item-wrapper {
              width: 90%;
              display: flex;
              flex-direction: column;
              gap: 30px;
              justify-content: center;
              .item {
                display: flex;
                flex-direction: row;
                gap: 16px;
                svg {
                  path {
                    fill: #ffffff;
                  }
                }

                p {
                  color: #ffffff;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                }
              }
              .social {
                .logos {
                  display: flex;
                  flex-direction: row;
                  gap: 23px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1150px) {
  .busisness-expansion-usa {
    .banner {
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -90px;
        }
      }
    }
  }
}
@media (max-width: 1050px) {
  .busisness-expansion-usa {
    .banner {
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -216px;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .busisness-expansion-usa {
    .banner {
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -260px;
        }
      }
    }
    .section-2,
    .section-3 {
      padding: 20px;
      .row-1 {
        gap: 10px;
      }
    }
    .section-7 {
      // padding: 30px;
      .section-7-container {
        .connect-form {
          gap: 10px;
          padding-right: 20px;
          width: 90%;
          img {
            height: 300px;
            width: 300px;
          }
          form {
            .input-grp {
              gap: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .busisness-expansion-usa {
    .faq-section .accordion .accordion-header .accordion-button {
      font-size: 14px;
      line-height: unset;
      height: 74px;
    }
    .banner {
      .content {
        width: 400px;
      }
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -440px;
        }
      }
    }
    .section-2 {
      .row-1 {
        flex-direction: column;
        align-items: center;
        .left,
        .right {
          width: 100%;
          .header {
            text-align: center;
          }
        }
      }
    }
    .section-3 {
      margin: unset;
      .row-1 {
        flex-direction: column-reverse;
        align-items: center;
        .left,
        .right {
          width: 100%;
        }
      }
    }
    .section-4 {
      margin-top: 30px;
      .image-wrapper-web {
        svg {
          margin-top: -20px;
        }
      }
      .call-btn-wrapper {
        margin-top: -30px;
        // margin-bottom: -60px;
      }
      h1 {
        margin-bottom: 0px;
      }
      .image-wrapper-web {
        padding: 0px;
      }
      p {
        padding: 0px;
      }
    }
    .section-7 {
      // padding: 30px;
      .section-7-container {
        .connect-form {
          gap: 10px;
          padding-right: 20px;
          width: 90%;
          img {
            height: 300px;
            width: 300px;
          }
          form {
            .input-grp {
              gap: 12px;
            }
          }
        }
      }
    }

    .section-7 {
      // padding: 6px;
      padding-right: 0px;
      // margin: 50px 0;
      .section-7-container {
        .connect-form {
          padding: 6px;
          padding-right: 10px;
          gap: 6px;
          img {
            height: 240px;
            width: 240px;
          }
          form {
            .input-grp {
              gap: 8px;
              .call-grp {
                gap: 0px;
              }
            }
          }
        }
      }
    }
    .faq-section {
      margin-top: 0px;
      .faq-heading {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 650px) {
  .busisness-expansion-usa {
    .banner {
      margin-bottom: 40px;
      .verified-logo {
        left: 20px;
      }
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -560px;
        }
      }
      .content {
        width: 350px;
        left: 20px;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .faq-section {
      width: 100%;
      margin-bottom: 20px;
    }
    .section-2 {
      margin-bottom: 30px;
      .row-1 {
        .right {
          .header {
            h2 {
              font-size: 28px;
            }
            h1 {
              font-size: 28px;
              span {
                font-size: 36px;
              }
            }
          }
        }
      }
    }

    .section-3 {
      margin-bottom: 0px;
      .row-1 {
        .left {
          .header {
            margin-bottom: 15px;
            h2 {
              font-size: 28px;
            }
            h1 {
              font-size: 28px;
              span {
                font-size: 36px;
              }
            }
          }
        }
        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
    .section-4 {
      h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 43.58px;
        text-align: center;
        margin-bottom: 30px;
        span {
          font-size: 28px;
          font-weight: 700;
          line-height: 43.58px;
          .text-red {
            color: #e31e26;
          }
        }
      }
      .image-wrapper-web {
        display: none;
      }
      .call-btn-wrapper {
        margin-top: 0px;
        // margin-bottom: -60px;
      }
      .image-wrapper-mobile {
        display: flex;
        padding: 0px 10px;
        margin-bottom: 30px;
        svg {
          width: 100%;
        }
      }
      .steps-mobile {
        display: flex;
        margin-bottom: 30px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            font-size: 12px;
            font-weight: 700;
            line-height: 16.34px;
            color: #e31e26;
            span {
              color: #212529;
              font-size: 10px;
              font-weight: 400;
              line-height: 13.62px;
            }
          }
        }
      }
    }
    .section-7 {
      padding: 0;
      .section-7-container {
        // background-color: #000;
        padding: 20px;
        .section-heading {
          color: white;
          align-self: flex-start;
        }
        .connect-form {
          .contact-detail {
            flex-direction: column;
            gap: 30px;
            .left {
              width: 100%;
            }
            .right {
              width: 100%;
              justify-content: flex-start;
            }
          }

          img {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .busisness-expansion-usa{

    .banner {
      margin-bottom: 40px;
      .verified-logo {
        left: 20px;
      }
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -600px;
        }
      }
      .content {
        width: 350px;
        left: 20px;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .busisness-expansion-usa {
    .banner {
      .content {
        width: 300px;
        bottom: 20px;
      }
      .bg-image {
        img {
          height: 500px;
          object-fit: cover;
          object-position: -677px;
        }
      }
      .verified-logo {
        img {
          width: 50px;
        }
      }
    }
    .section-7 {
      .section-7-container {
        h1 {
          font-size: 34px;
          .vertical-text {
            top: 12px;
          }
        }
        .connect-form {
          width: 100%;
          form {
            width: 100%;
            gap: 8px;
            .input-grp {
              flex-direction: column;
              .input {
                width: 100%;
              }
              input {
                width: 100%;
              }
              .call-grp {
                width: 100%;
              }
            }
            button {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}
