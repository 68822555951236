@import "/src/variables";
@import "/src/index.scss";

.products-and-services-main{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px 120px;
    h1{
        color: $--my-black;
        font-size: 40px;
        font-weight: 600;
        margin: 0;
    }
    p{
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: $--secondary-font-color;
    }
}
@media (max-width: 1100px){
    .products-and-services-main{
        padding: 40px;
    }
}
@media (max-width: 800px){
    .products-and-services-main{
        padding: 30px;
    }
}
@media (max-width: 650px){
    .products-and-services-main{
        padding: 40px 20px;
    }
}