@import "/src/variables";
@import "/src/index.scss";

.footer-main {
  background: black;
  .footer-section {
    .copyright {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      color: #ffffff;
    }
    .row-1 {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      justify-content: space-evenly;
      .f-col-1 {
        // width: 40%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        gap: 50px;
        .social-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: fit-content;
          .social {
            .logos {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 23px;
              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
        .email-subscribe {
          display: flex;
          flex-direction: row;
          gap: 10px;
          input {
            width: 271px;
            height: 34px;
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            color: white;
            padding-left: 5px;
            background: transparent;
            border: 1px solid #ffffff;
            border-radius: 4px;
          }
          button {
            width: 62px;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #e31e26;
            background: transparent;
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            color: white;
            transition: ease-in all 200ms;
          }
          button:hover {
            transition: ease-in all 200ms;
            background: #e31e26;
          }
        }
      }
      .col-wrapper {
        display: flex;
        gap: 40px;
        .f-col-2 {
          width: 120px;

          .about-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            a {
              color: #ffffff;
              text-decoration: none;
              transition: ease-in all 200ms;
              font-size: 12px;
              font-weight: 600;
              line-height: 16.34px;
            }
            a:hover {
              color: #e31e26;
              transition: ease-in all 200ms;
            }
          }
        }
        .f-col-3 {
          width: 285px;
          display: flex;
          justify-content: center;
          .contact-us-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: end;
            h4 {
              width: fit-content;
              font-size: 14px;
              font-weight: 700;
              line-height: 19.07px;
              color: #ffffff;
            }
            .item-wrapper {
              width: 90%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              .item {
                display: flex;
                flex-direction: row;
                gap: 14px;
                svg {
                  path {
                    fill: #ffffff;
                  }
                }

                p {
                  color: #ffffff;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 22px;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .f-section-1 {
    width: 85%;
    margin: auto;
    padding: 50px 0px 30px;
  }
  .f-section-2 {
    padding: 20px;
    border-top: 1px solid white;
    text-align: center;
  }
}

@media (max-width: 1000px) {
  .footer-main {
    padding: 20px;
    .f-section-1 {
      margin: 0px 0px;
      width: 100%;
      padding: 50px;
    }
    .footer-section {
      .row-1 {
        justify-content: space-between;
        .f-col-1 {
          .email-subscribe input {
            width: 230px;
          }
        }
        .col-wrapper{
          gap: 40px;

        }
      }
      .contact-info,
      .about-items {
        padding-left: 20px;
      }
    }
  }
}
@media (max-width: 900px) {
  .footer-main {
    padding: 20px;
    .f-section-1 {
      margin: 0px 0px;
      width: 100%;
      padding: 30px;
    }
    .footer-section {
      .row-1 {
        justify-content: space-between;
        .f-col-1 {
          .email-subscribe input {
            width: 230px;
          }
        }
        .f-col-3 {
          width: 230px;
        }
      }
      .contact-info,
      .about-items {
        padding-left: 20px;
      }
    }
  }
}
@media (max-width: 850px) {
  .footer-main {
    padding: 20px;
    .f-section-1 {
      margin: 0px 0px;
      width: 100%;
      padding: 30px;
    }
    .footer-section {
      .row-1 {
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        .col-wrapper{
          justify-content: center;
          .f-col-2 {
            align-items: flex-start;
            .about-items{
              // padding-right: 50px;
              padding-left: 0px;
              align-items: flex-start;
            }
          }
          .f-col-3 {
            .contact-us-wrapper{
              justify-content: flex-start;
            }
          }
        }
        .f-col-1 {
          .email-subscribe input {
            width: 230px;
          }
        }
      }
      .contact-info,
      .about-items {
        padding-left: 50px;
      }
    }
  }
}
@media (max-width: 650px) {
  .footer-main {
    padding: 20px;
    gap: 40px;
    .footer-section {
      gap: 20px;
      h4 {
        text-align: left;
        width: 100%;
      }
      .contact-info {
        flex-direction: column;
        padding-left: 20px;
        .address {
          max-width: unset;
        }
      }
      .about-items {
        gap: 20px;
      }
    }
  }
}
@media (max-width: 500px) {
  .footer-main {
    padding: 20px;
    .f-section-1{
      padding: 30px 0px;
    }
    .footer-section {
      padding: 30px 0px;
      .contact-info {
        padding-left: 20px;
      }
    }
  }
}
