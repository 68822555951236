@import "/src/variables";
@import "/src/index.scss";

.about-us-main {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // // gap: 60px;
    padding: 60px 0;

    .heading {
        padding: 20px;
        h1 {
            color: $--my-black;
            font-size: 40px;
            font-weight: 600;
            text-align: center;
        }
        p {
            color: $--secondary-font-color;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }
    .section-1 {
        display: flex;
        gap: 60px;
        background: #f9fafb;
        padding: 0px 110px;

        img {
            width: 50%;
        }
        .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .about {
                font-size: 36px;
                font-weight: 600;
                color: #10182b;
            }
            .statistics {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                place-items: start;
                width: 100%;
                .item {
                    .number {
                        color: #10182b;
                        font-size: 45px;
                        font-weight: 600;
                        margin: 0;
                    }
                    .text {
                        color: #475467;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 0;
                    }
                }
            }
        }
    }
    .section-2 {
        .section-container {
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // gap: 16px;
            padding: 50px 24px;
            h1 {
                color: $--my-black;
                font-size: 32px;
                font-weight: 600;
                margin: 0;
                margin-bottom: 16px;
                text-align: center;
            }
            .client-container{
                display: flex;
                justify-content: center;
                .client-logo-swiper {
                    .swiper-wrapper {
                        margin-left: 50%;
                    }
                }
            }
        }
    }
    .section-3 {
        padding: 60px 110px;
        display: flex;
        flex-direction: column;
        margin: auto;
        // gap: 60px;
        width: fit-content;
        overflow-x: hidden;
        .people {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            padding: var(--spacing-3, 24px);
            width: 250px;
            border-radius: 12px;
            background: #f9fafb;
            margin: 30px 0;

            img {
                height: 80px;
                width: 80px;
                border-radius: 50%;
                object-fit:contain;
            }
            p {
                margin: 0;
                text-align: center;
            }
            .person-details {
                .name {
                    color: #101828;
                    font-size: 18px;
                    font-weight: 600;
                }
                .role {
                    color: $--my-red;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .description {
                color: #475467;
                font-size: 16px;
                font-weight: 400;
            }
            .socials {
                display: flex;
                gap: 16px;

                .item {
                    height: 30px;
                    width: 30px;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;

                    svg {
                        height: 16px;
                        width: 16px;
                        path {
                            fill: white;
                        }
                    }
                }
                .instagram {
                    background: #ea3e27;
                }
                .facebook {
                    background: #1877f2;
                }
                .linkedIn {
                    background: #2867b2;
                }
                .twitter {
                    background: #1da1f2;
                }
            }
        }
        .peoples {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            justify-content: center;
        }
        .peoples-mobile{
            display: none;
            width: 100vw;
            padding: 20px 0;
            transform: translate(-40%);
            .people{
                min-width: 60vw !important;
            }
            .current-people{
                transform: scale(1.2);
                box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.10);
            }
        }
        .arrows {
            margin: auto;
            display: none;
            .arrow {
                width: 36px;
                height: 36px;
                padding: 0;
                display: inline-grid;
                place-items: center;
                border-radius: 50%;
                svg {
                    transform: rotate(-180deg);
                }
                &:before {
                    background-color: white;
                }
                &:hover {
                    border-color: $--my-black;

                    svg {
                        path {
                            stroke: $--my-black;
                        }
                    }
                }
            }
            .reverse {
                transform: rotate(-180deg);
                margin-left: 10px;
            }
        }
    }
    .section-4 {
        background: #f9fafb;
        padding: 60px 0;
        width: 100vw;
        .section-container {
            width: 1250px;
            margin: auto;
            h1 {
                color: $--my-black;
                font-size: 36px;
                font-weight: 700;
            }
            .description {
                color: $--secondary-font-color;
                font-size: 20px;
                font-weight: 400;
            }
            .case-study-container {
                display: flex;
                gap: 25px;
                overflow-x: scroll;
                scroll-behavior: smooth;
                margin-bottom: 30px;
                // pointer-events: none;
                .case-study {
                    min-width: 400px;
                    max-width: 400px;
                    height: fit-content;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    cursor: pointer;
                    img {
                        height: 250px;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    .title {
                        color: $--my-black;
                        font-size: 24px;
                        margin: 0;
                    }
                    .about {
                        font-size: 14px;
                        font-weight: 400;
                        color: $--secondary-font-color;
                        margin: 0;
                    }
                    .read {
                        font-size: 14px;
                        font-weight: 600;
                        color: $--my-red;
                        text-transform: capitalize;
                        text-align: right;
                        cursor: pointer;
                        margin: 0;
                    }
                }
            }
            .case-study-container::-webkit-scrollbar {
                width: 0px;
            }
            .pagination {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 20px;

                .pagination-item {
                    height: 16px;
                    width: 16px;
                    background-color: #b9b9b9;
                    border-radius: 50%;
                    outline-offset: 4px;
                    cursor: pointer;
                }
                .pagination-item:hover {
                    outline: 2px solid $--my-red;
                    background-color: $--my-red;
                }
                .activated {
                    outline: 2px solid $--my-red;
                    background-color: $--my-red;
                }
            }
        }
    }
}
@media (max-width: 1300px) {
    .about-us-main {
        .section-2{
            .section-container{
                .client-container {
                    .client-logo-swiper {
                        margin-left: unset;
                    }
                }
            }
        }
        .section-1,.section-3 {
            padding: 60px;
        }
        .section-4 {
            .section-container {
                width: 950px;
                .case-study-container {
                    .case-study {
                        min-width: 300px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .about-us-main {
        .section-1,.section-3 {
            padding: 60px;
            gap: 30px;
        }
        .section-4 {
            .section-container {
                width: 800px;
                .case-study-container {
                    .case-study {
                        min-width: 250px;
                    }
                }
            }
        }
    }
}
@media (max-width: 850px) {
    .about-us-main {
        .section-3 {
            padding: 40px;
        }
        .section-1 {
            padding: 40px;
            flex-direction: column;
            img {
                width: 100%;
            }
            .content {
                width: 100%;
            }
        }
        .section-4 {
            .section-container {
                width: 650px;
                .case-study-container {
                    .case-study {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}
@media (max-width: 700px) {
    .about-us-main {
        // gap: 30px;
        padding: 30px 0;
        .section-2,.section-3{
            margin: 40px 0px;
        }
        .section-1,.section-3 {
            gap: 20px;
            padding: 20px;
        }
        .section-3{
            .peoples{
                gap: 16px;
                
                .people{
                    width: 220px;
                    padding: 20px;
                    margin: 0;
                }
            }
        }
        .section-4 {
            padding: 30px 0;
            .section-container {
                width: 525px;
                .case-study-container {
                    .case-study {
                        min-width: 250px;
                    }
                }
            }
        }
    }
}
@media (max-width: 550px) {
    .about-us-main {
        .heading{
            padding: 0px 30px;
            h1{
                font-size: 32px;
            }
            p{
                font-size: 12px;
            }
        }
        .section-1 {
            gap: 20px;
            .content{
                .about{
                    font-size: 18px;
                }
                .statistics{
                    .item{
                        .number{
                            font-size: 36px;
                        }
                        .text{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .section-3{
            gap: 20px;
            padding: 10px;
            .peoples{
                display: none;
                .people{
                    width: 100%;
                }
            }
            .peoples-mobile{
                display: flex;
            }
            .arrows{
                display: block;
            }
        }
        .section-4 {
            .section-container {
                width: 425px;
                .case-study-container {
                    .case-study {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}
@media (max-width: 450px) {
    .about-us-main {
        .heading{
            h1{
                font-size: 36px;
            }
        }
        .section-1{
            .content{
                .about{
                    font-size: 32px;
                }
                .statistics{
                    .item{
                        .number{
                            font-size: 35px;
                        }
                    }
                }
            }
        }
        .section-2{
            .section-container{
                h1{
                    font-size: 24px;
                }
            }
        }
        .section-4 {
            .section-container {
                width: 350px;
                .case-study-container{
                    .case-study{
                        min-width: 350px;
                    }
                }
                .pagination{
                    .pagination-item{
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .about-us-main {
        .section-4 {
            .section-container {
                width: 300px;
                .case-study-container{
                    .case-study{
                        min-width: 300px;
                    }
                }
            }
        }
    }
}
@media (max-width: 350px) {
    .about-us-main {
        .section-4 {
            .section-container {
                width: 275px;
                .case-study-container{
                    .case-study{
                        min-width: 275px;
                    }
                }
            }
        }
    }
}
