@import "/src/variables";
@import "/src/index.scss";

.category-select{
    // width: 100%;
    display: flex;
    flex-direction: column;
    .arrow {
        position: absolute;
        top: 120px;
    }
    h3{
        color: $--my-black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .choices {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        max-width: 1000px;
        margin-bottom: 50px;
        button {
            &:before {
                background-color: $--my-black;
            }
            &:hover {
                border-color: $--my-black;
            }
        }
        .selected {
            background-color: $--my-black;
            color: white;
        }
    }
    button{
        width: fit-content;
        align-self: flex-end;
    }
}
@media (max-width: 750px){
    .category-select{
        margin-top: 50px;
    }
}
@media (max-width: 650px){
    .category-select{
        margin-top: 70px;
        h3{
            font-size: 32px;
        }
    }
}