@import "/src/variables";
@import "/src/index.scss";

.home-main {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .section {
    max-width: 1200px;

    .section-container {
      .section-heading {
        color: $--my-black;
        text-transform: capitalize;
        font-weight: 400;
        position: relative;
        margin-bottom: 0;
        p {
          margin-bottom: 0;
        }
        .vertical-text {
          position: absolute;
          color: $--my-red;
          font-weight: 600;
          transform: rotate(-90deg);
        }
      }
      .loading-section-heading {
        height: 80px;
        width: min(100%, 400px);
        margin-bottom: 20px;
      }
      .animate {
        animation: typeWriter 2s;
        animation-name: typeWriter;
        animation-duration: 1.5s;
        animation-delay: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        display: inline-block;
        width: 0%;
        overflow: hidden;
        border-right: 1px solid $--my-red;
      }
      @keyframes typeWriter {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
  .section-1 {
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    .section-1-container {
      display: flex;
      justify-content: space-between;
      // gap: 140px;
      .main-text {
        display: flex;
        flex-direction: column;
        // gap: 8px;

        h1 {
          font-size: 64px;
          text-wrap: nowrap;
        }
        h4 {
        }
        .slogan {
          margin-bottom: 40px;
          color: #8c8c8c;
        }
        .line-1 {
          font-weight: 400;
          font-size: 24px;
          .vertical-text {
            font-size: 12px;
            top: 70px;
            left: -70px;
          }
        }
        .line-2 {
          display: inline-block;
          text-wrap: nowrap;
          animation: businessTagLineAnimation 1.5s ease 0s 1 forwards;
          transition: all 1s;
          overflow: hidden;
          border-right: 1px solid $--my-red;
          font-size: 48px;
          // margin-left: 10px;
          text-transform: capitalize;
          span {
            color: $--my-red;
          }
        }
        @keyframes businessTagLineAnimation {
          0% {
            width: 0px;
          }
          40% {
            width: 125px;
          }
          55% {
            width: 210px;
          }
          85% {
            width: 375px;
          }
          100% {
            width: 500px;
          }
        }
        @media (max-width: 1070px) {
          .line-1 {
            font-weight: 400;
            font-size: 24px;
            .vertical-text {
              font-size: 12px;
              top: 70px;
              left: -70px;
            }
          }
          .line-2 {
            display: inline-block;
            text-wrap: nowrap;
            animation: businessTagLineAnimation 1.5s ease 0s 1 forwards;
            transition: all 1s;
            overflow: hidden;
            border-right: 1px solid $--my-red;
            font-size: 36px;
            // margin-left: 10px;
            text-transform: capitalize;
            span {
              color: $--my-red;
            }
          }
          @keyframes businessTagLineAnimation {
            0% {
              width: 0px;
            }
            40% {
              width: 93.75px;
            }
            55% {
              width: 187.5px;
            }
            85% {
              width: 281.25px;
            }
            100% {
              width: 375px;
            }
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #212529;
          width: min(100%, 500%);
        }
        div {
          display: flex;
          justify-content: space-between;
          // width: 420px;
          width: min(100%, 420px);

          z-index: 0;

          .call-btn {
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 30px;
            .primary-btn {
              border: 1px solid var(--color-brand-logo-1, #e31e26);
              box-shadow: 0px 0px 4px 1px #e31e26 inset;
              transition: all 0.18s ease-in;
              text-wrap: nowrap;
              &:before {
                transition: all 0.18s ease-in;
              }
            }
            .call-icon {
              background-color: $--my-red;
              padding: 8px;
              display: inline-grid;
              place-items: center;
              border-radius: 50%;
              position: absolute;
              transition: all 0.18s ease-in 0s;
              left: 0;
              z-index: -1;
              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
          .call-btn:hover > .call-icon {
            left: calc(100% + 8px);
          }
          .my-svg {
            position: relative;
            // transform: translate(90%, -50%);
            img {
              // position: absolute;
            }
            .animate-SVG {
              position: absolute;
              left: 0;
              animation: svgAnimation 5.5s ease 0s infinite;
            }
            @keyframes svgAnimation {
              0% {
                transform: rotate(0deg);
              }
              20% {
                transform: rotate(0deg);
              }
              30% {
                transform: rotate(-5deg);
              }
              50% {
                transform: rotate(-5deg);
              }
              60% {
                transform: rotate(-15deg);
              }
              80% {
                transform: rotate(-15deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
        }
        .gif {
          position: relative;
          display: flex;
          align-items: center;
          width: 260px;
          transform: translateX(-50%);
          margin-top: 30px;
          .HTML-Embed {
            pointer-events: none;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
          }
          .play {
            position: absolute;
            right: 0;
            background-color: #ffffff;
            border-radius: 50%;
            padding: 8px;
            display: grid;
            place-items: center;
            cursor: pointer;
          }
        }
      }
      .my-svg {
        position: relative;
        display: flex;
        align-items: end;
        // transform: translate(90%, -50%);
        img {
          // position: absolute;
        }
        .animate-SVG {
          position: absolute;
          left: 0;
          animation: svgAnimation 5.5s ease 0s infinite;
        }
        @keyframes svgAnimation {
          0% {
            transform: rotate(0deg);
          }
          20% {
            transform: rotate(0deg);
          }
          30% {
            transform: rotate(-5deg);
          }
          50% {
            transform: rotate(-5deg);
          }
          60% {
            transform: rotate(-15deg);
          }
          80% {
            transform: rotate(-15deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
      .main-text-loading-cmp {
        width: 100%;
        height: 400px;
        margin-right: 30px;
      }

      .statistics {
        display: inline-flex;
        flex-direction: column;
        gap: 44px;

        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          gap: 6px;
          color: $--my-black;

          .number {
            font-size: 40px;
            font-weight: 400;
          }
          .text {
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
          }

          .statistics-loading-cmp {
            height: 100px;
            width: 150px;
          }
        }
      }
    }
  }
  .section-2 {
    // width: 75%;
    padding: 50px 0px;
    margin-top: 150px;
    display: flex;
    background: #e31e2633;

    // align-self: flex-end;
    // justify-self: flex-end;
    .section-2-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      // align-items: center;
      // gap: 16px;
      // padding: 50px 24px;
      .section-heading {
        font-family: Open Sans;
        font-size: 32px;
        font-weight: 700;
        line-height: 43.58px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-bottom: 30px;

        span {
          font-family: Open Sans;
          font-size: 40px;
          font-weight: 700;
          line-height: 54.47px;
          text-align: center;
          .text-red {
            color: #e31e26;
          }
        }
      }
      .client-container {
        display: flex;
        justify-content: center;
        width: 100%;
        .client-logo-swiper {
          .swiper-wrapper {
            margin-left: 0%;
            width: 250px;
            .client {
              width: 250px !important;
              padding: 20px;
              img {
                width: 100% !important;
              }
            }

            .swiper-slide {
              width: 250px !important;
            }
          }
        }
        .loading-client-logo-container {
          height: 140px;
          width: 100%;
          overflow-x: hidden;
          display: flex;
          gap: 20px;

          .loading-client {
            height: 100%;
            min-width: 140px;
          }
        }
      }
      // .client-logo-swiper {
      //     // display: none;
      //     // width: 80vw;
      //     // margin: auto;
      //     // z-index: 0;
      //     // position: relative;
      //     // // margin-left: 20%;

      //     // .swiper-wrapper {
      //     //     margin: auto;
      //     //     width: fit-content;
      //     //     align-items: center;
      //     //     .client{
      //     //         height: 85px;
      //     //         width: 85px;
      //     //         border-radius: 5px;
      //     //         border: 0.487px solid #d6d6d6;
      //     //         display: flex;
      //     //         align-items: center;
      //     //         justify-content: center;
      //     //         // padding: 10px;
      //     //         background-color: #F7F7F7;

      //     //         img {
      //     //             max-width: 100%;
      //     //             width: 75px;
      //     //             height: 75px;
      //     //             max-height: 100%;
      //     //             object-fit: contain;
      //     //             mix-blend-mode: multiply;
      //     //         }
      //     //     }
      //     // }
      // }
      // .client-logo-container {
      //     display: flex;
      //     // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      //     gap: 24px;
      //     flex-wrap: wrap;

      //     .client{
      //         height: 100px;
      //         width: 150px;
      //         border-radius: 5px;
      //         border: 0.487px solid #d6d6d6;
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         // padding: 10px;
      //         background-color: #F7F7F7;

      //         img {
      //             max-width: 100%;
      //             width: 120px;
      //             height: 70px;
      //             max-height: 100%;
      //             object-fit: contain;
      //             mix-blend-mode: multiply;
      //         }
      //     }
      // }
    }
  }
  .section-milestones {
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    // margin-top: 60px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .left {
      width: 50%;
      margin-top: 50px;

      font-weight: 700;
      color: #8c8c8c;
      .component-loading {
        height: 300px;
        width: 100%;
      }
      h2 {
        color: #202020;
        margin-bottom: 30px;
      }
      p {
        color: #202020;
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        li::marker {
          color: #e31e26;
        }
        span {
          color: #e31e26;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: end;
      .milestones-svg {
        width: 100%;
      }
      .component-loading {
        height: 300px;
        width: 100%;
        margin-top: 50px;
      }
    }
  }

  .section-wrapper {
    .component-loading {
      margin-top: 100px;
      height: 400px;
    }
  }
  .section-our-team {
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    position: relative;
    .heading-container {
      position: absolute;
      width: 241px;
      height: 49px;
      background: #e31e26;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px;

      .heading {
        font-size: 28px;
        font-weight: 700;
        line-height: 48px;
        color: #ffffff;
      }
    }
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
      object-position: top;
    }
    .team-detail-container {
      position: absolute;
      width: 75%;
      height: 140px;
      bottom: 0;
      right: 0;
      display: flex;
      .triangel {
        width: 0;
        height: 0;
        border-left: 140px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 140px solid #e31e26;

        // margin: 20px auto;
      }
      .team-detail {
        background: #e31e26;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 5px 10px;
        color: #ffffff;
      }
      @media (max-width: 1200px) {
        width: 80%;
      }
      @media (max-width: 1050px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 95%;
      }
      @media (max-width: 800px) {
        width: 100%;
        height: 135px;
        .triangel {
          display: none;
        }
        .team-detail {
          padding: 10px;
          text-align: start;
        }
      }
      @media (max-width: 700px) {
        .team-detail {
          font-size: 13px;
        }
      }
      @media (max-width: 700px) {
        .team-detail {
          font-size: 13px;
        }
      }
    }
  }
  .section-our-founder {
    margin: 100px auto 60px;
    width: 75%;
    display: flex;
    flex-direction: column;
    .heading {
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      span {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        color: #e31e26;
      }
    }
    .loading-section-heading {
      height: 100px;
    }
    .loading-content {
      height: 300px;
      margin-top: 30px;
    }
    .loading-img {
      height: 430px;
      width: 100%;
    }
    .row-1 {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: end;
      .left {
        h5 {
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
          color: #8c8c8c;
          margin-bottom: 25px;
        }
        width: 60%;
        .p-2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          text-align: justified;
          margin-top: 10px;
          padding: 4px !important;
        }
        .p-3 {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          text-align: justified;
          padding: 4px !important;
          margin-top: 10px;
        }

        .footer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 25px;

          .call-btn {
            display: flex;
            align-items: center;
            position: relative;
            text-decoration: none;
            .primary-btn {
              border: 1px solid var(--color-brand-logo-1, #e31e26);
              box-shadow: 0px 0px 4px 1px #e31e26 inset;
              transition: all 0.18s ease-in;
              text-wrap: nowrap;
              &:before {
                transition: all 0.18s ease-in;
              }
            }
          }
          .author {
            .name {
              font-size: 30px;
              font-weight: 600;
              line-height: 40.85px;
              text-align: end;
            }
            .detail {
              font-size: 14px;
              font-weight: 600;
              line-height: 19.07px;
              letter-spacing: 0.38em;
              text-align: right;
              color: #e31e26;
            }
          }
        }
      }
      .right {
        width: 40%;
        display: flex;
        justify-content: end;
      }
    }
    .row-2 {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      align-items: end;
      margin-top: 100px;
      .left {
        h5 {
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
          color: #8c8c8c;
          margin-bottom: 25px;
        }
        width: 60%;
        .p-2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          text-align: justified;
          margin-top: 10px;
        }
        .p-3 {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          text-align: justified;
          margin-top: 20px;
        }

        .footer {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          margin-top: 25px;

          .call-btn {
            display: flex;
            align-items: center;
            position: relative;
            .primary-btn {
              border: 1px solid var(--color-brand-logo-1, #e31e26);
              box-shadow: 0px 0px 4px 1px #e31e26 inset;
              transition: all 0.18s ease-in;
              text-wrap: nowrap;
              &:before {
                transition: all 0.18s ease-in;
              }
            }
          }
          .author {
            .name {
              font-size: 30px;
              font-weight: 600;
              line-height: 40.85px;
              text-align: start;
            }
            .detail {
              font-size: 14px;
              font-weight: 600;
              line-height: 19.07px;
              letter-spacing: 0.38em;
              text-align: start;
              color: #e31e26;
            }
          }
        }
      }
      .right {
        width: 40%;
        display: flex;
        justify-content: start;
      }
    }
  }
  .section-why-us {
    background: #e31e261a;
    padding: 20px 0px;
    .conatiner {
      margin: 0 auto;
      width: 65%;
      .heading {
        text-align: center;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        margin-bottom: 40px;

        span {
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;

          .text-red {
            color: #e31e26;
          }
        }
      }
      .detail-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
          }
        }
      }
    }
  }
  .section-3 {
    margin: auto;
    display: flex;
    // justify-content: center;
    width: 75%;
    .section-3-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;
      width: 100%;
      h1 {
        font-size: 40px;
        p {
          margin: 0;
        }
        .vertical-text {
          font-size: 14px;
          top: 44px;
          left: -44px;
        }
        .bold {
          max-width: 205px;
          text-wrap: nowrap;
        }
      }
      .stratergies {
        display: flex;
        gap: 40px;
        width: 600px;

        .stratergy {
          display: flex;
          flex-direction: column;
          color: $--my-black;
          gap: 16px;
          .title {
            p {
              font-size: 24px;
              margin: 0;
              text-transform: capitalize;
            }
            .red {
              font-size: 36px;
              color: $--my-red;
            }
          }
          .content {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
          }
        }
        .loading-strat {
          height: 250px;
          width: 200px;
        }
      }
    }
  }
  .logo-bg {
    width: 110vw;
    height: 410px;
    margin-top: 100px;
    margin-bottom: 100px;
    background: url("../../Assets/home/logo-bg.svg");
    background-size: contain;
    background-size: 200%;
    transform: rotate(-15deg);
    animation: logo-bg-animation 6s linear 0s infinite forwards;
    background-repeat: no-repeat;
    position: relative;
    right: 80px;
    img {
      overflow: hidden;
      height: 100%;
      width: 100vw;
    }
  }
  @keyframes logo-bg-animation {
    0% {
      background-position-x: 100%;
    }
    // 10% {
    //     background-position-x: 0%;
    // }
    // 33% {
    //     background-position-x: 50%;
    // }
    // 43% {
    //     background-position-x: 50%;
    // }
    // 66% {
    //     background-position-x: 100%;
    // }
    // 76% {
    //     background-position-x: 100%;
    // }
    100% {
      background-position-x: 0%;
    }
  }
  .section-4 {
    margin: 80px auto 40px auto;
    width: 75%;
    display: flex;
    // justify-content: center;

    .section-4-container {
      display: flex;
      flex-direction: row;
      gap: 60px;
      width: 1000px;
      img {
        height: 400px;
        width: 400px;
        transform: rotateY(180deg);
      }
      .loading-about-us-img {
        height: 100%;
        width: 100%;
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        h1 {
          font-size: 40px;
          .vertical-text {
            font-size: 12px;
            top: 40px;
            left: -44px;
          }
          .bold {
            max-width: 220px;
            text-wrap: nowrap;
          }
        }
        .call-btn {
          display: flex;
          align-items: center;
          position: relative;
          width: 216px;
          .primary-btn {
            border: 1px solid var(--color-brand-logo-1, #e31e26);
            box-shadow: 0px 0px 4px 1px #e31e26 inset;
            transition: all 0.18s ease-in;
            text-wrap: nowrap;
            &:before {
              transition: all 0.18s ease-in;
            }
          }
          .call-icon {
            background-color: $--my-red;
            padding: 8px;
            display: inline-grid;
            place-items: center;
            border-radius: 50%;
            position: absolute;
            transition: all 0.18s ease-in 0s;
            left: 0;
            z-index: -1;
            svg {
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .call-btn:hover > .call-icon {
          left: 100%;
        }

        .loading-about-us-content {
          height: 300px;
          width: 400px;
        }
      }
    }
  }
  .section-5 {
    width: 75%;
    margin: 50px auto;
    display: flex;
    // justify-content: flex-end;
    .section-5-container {
      display: flex;
      flex-direction: column;
      // gap: 20px;
      width: 100%;
      margin-right: 30px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-size: 40px;
          p {
            margin: 0;
          }
          .vertical-text {
            font-size: 12px;
            top: 40px;
            left: -50px;
          }
          .bold {
            max-width: 225px;
            margin: 0;
            text-wrap: nowrap;
          }
        }
        .arrows {
          .reverse {
            transform: rotate(-180deg);
            margin-left: 10px;
          }
        }
      }
      .categories-drop-down {
        display: none;
        flex-direction: column;
        font-size: 12px;
        .selected {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #c7c7cd;
          padding: 6px 12px;
          p {
            margin: 0;
            color: $--my-red;
            font-weight: 600;
          }
        }
        .categories {
          display: none;
        }
      }
      .open {
        .selected {
          img {
            transform: rotate(180deg);
          }
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        .categories {
          border: 1px solid #c7c7cd;
          border-top: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          display: block;
          .category {
            padding: 4px 12px;
            color: $--secondary-font-color;
            cursor: pointer;
          }
        }
      }
      .product-container {
        display: flex;
        // justify-content: space-between;
        gap: 8px;
        .product-categories {
          display: flex;
          flex-direction: column;
          height: 350px;
          overflow-y: scroll;
          gap: 20px;
          overflow-x: hidden;
          min-width: 200px;
          width: 200px;
          margin-right: 20px;
          .prod-category {
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 700;
            padding-right: 20px;
            border-bottom: 1px solid var(--2-a-292-a, #2a292a);
            cursor: pointer;
            p {
              margin: 0;
            }
            img {
              display: none;
            }
          }
          .selected-category,
          .prod-category:hover {
            border-color: $--my-red;
            p {
              color: $--my-red;
            }
            img {
              display: block;
            }
          }
        }
        .product-categories::-webkit-scrollbar {
          width: 0px;
        }
        .products-container {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          overflow-x: scroll;
          scroll-behavior: smooth;
          height: 370px;
          .product {
            min-width: 250px;
            max-width: 250px;
            border-radius: 24px;
            box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            height: 350px;
            // height: fit-content;
            display: flex;
            flex-direction: column;
            gap: 8px;
            cursor: pointer;
            // padding-bottom: 14px;
            img {
              height: 150px;
              width: 100%;
              object-fit: contain;
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 200px;
              .text {
                display: flex;
                flex-direction: column;
                .title {
                  color: $--my-black;
                  width: 200px;
                  margin: 0;
                  padding: 0 12px;
                }
                .about {
                  font-size: 12px;
                  color: $--secondary-font-color;
                  margin: 0;
                  padding: 0 12px;
                  height: 10px;
                  // overflow: hidden;
                }
              }
              .button {
                background-color: white;
                padding: 10px 0px;
                display: flex;
                justify-content: center;
                button {
                  font-size: 10px;
                  width: 155px;
                  // margin-left: 12px;
                  justify-self: flex-end;
                }
              }
            }
          }
        }
        .products-container::-webkit-scrollbar {
          width: 0px;
        }
      }
    }
  }
  .section-6 {
    margin: 50px auto 0px;
    width: 75%;
    display: flex;
    // justify-content: center;
    .section-6-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 14px;
      // width: 1050px;
      // margin: 0 100px 0 0;
      img {
        width: 40%;
        // transform: rotateY(180deg);
        // height: 450px;
      }
      .text-container {
        h1 {
          font-size: 40px;
          font-weight: 400;
          margin-bottom: 20px;
          .vertical-text {
            font-size: 14px;
            top: 40px;
            left: -40px;
          }

          .bold {
            max-width: 252px;
            margin: 0;
            text-wrap: nowrap;
            .text-red {
              color: #e31e26;
            }
          }
        }
        p {
          color: $--my-black;
          font-size: 16px;
          font-weight: 400;
        }
        .call-btn {
          display: flex;
          align-items: center;
          position: relative;
          // width: 216px;
          margin-top: 30px;
          .primary-btn {
            border: 1px solid var(--color-brand-logo-1, #e31e26);
            box-shadow: 0px 0px 4px 1px #e31e26 inset;
            transition: all 0.18s ease-in;
            text-wrap: nowrap;
            &:before {
              transition: all 0.18s ease-in;
            }
          }
          .call-icon {
            background-color: $--my-red;
            padding: 8px;
            display: inline-grid;
            place-items: center;
            border-radius: 50%;
            position: absolute;
            transition: all 0.18s ease-in 0s;
            left: 0;
            z-index: -1;
            svg {
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .call-btn:hover > .call-icon {
          // left: 100%;
        }
      }
    }
  }
  .section-7 {
    display: flex;
    justify-content: flex-end;
    // padding: 80px;
    .section-7-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      background: #e31e26;
      .connect-form {
        margin: 40px auto;
        padding: 20px;
        border: 1px solid #ffffff;
        border-radius: 20px;
        width: 65%;
        .form-error {
          color: white !important;
        }
        .section-heading {
          font-size: 32px;
          font-weight: 700;
          line-height: 43.58px;
          letter-spacing: 0.01em;
          color: #ffffff;
          text-align: center;
          margin-bottom: 30px;
        }
        .contact-detail {
          display: flex;
          flex-direction: row;
          padding: 10px;
          gap: 20px;
          .left {
            width: 50%;

            form {
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              input,
              textarea {
                border: 1px solid #ffffff;
                width: Fill (439.28px) px;
                height: 44px;
                padding: 10px;
                gap: 10px;
                border-radius: 4px;
                background: transparent;
                color: #ffffff;
              }
              input::placeholder,
              textarea::placeholder {
                color: #ffffff80;
              }
              input:focus,
              textarea:focus {
                border-color: white;
              }
              .input-grp {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                input {
                  width: 100%;
                }
                .input {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  gap: 10px;
                  label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                  }
                }

                .call-grp {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                    margin-bottom: 10px;
                  }

                  input {
                    border: none;
                    width: 100%;
                  }
                  .input {
                    width: 100%;
                    flex-direction: row;
                    gap: 8px;
                    border: 1px solid #ffffff;
                    border-radius: 4px;
                    padding-left: 10px;
                  }
                  select {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: white;
                    option {
                      color: black;
                    }
                  }
                  .country-code {
                    position: relative;
                    p {
                      margin: 0;
                    }
                    .selected-code {
                      display: flex;
                      gap: 8px;
                      align-items: center;

                      p {
                        color: white;
                        font-size: 16px;
                      }
                      img {
                        height: 16px;
                        width: 16px;
                      }
                    }
                    .options {
                      display: none;
                      position: absolute;
                      color: #c6c6c6;
                      z-index: 2;
                      p {
                        margin: 2px 0;
                        cursor: pointer;
                      }
                    }
                  }
                  .open {
                    .selected-code {
                      img {
                        transform: rotate(180deg);
                      }
                    }
                    .options {
                      display: block;
                    }
                  }
                }
              }
              button {
                border-radius: 4px;
                height: 44px;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #e31e26;
                border: none;
                background: #ffffff;
              }
              button:hover {
                background: rgb(253 234 234);
              }
            }
          }
          .right {
            width: 50%;
            display: flex;
            justify-content: end;
            .item-wrapper {
              width: 90%;
              display: flex;
              flex-direction: column;
              gap: 30px;
              justify-content: center;
              .item {
                display: flex;
                flex-direction: row;
                gap: 16px;
                svg {
                  path {
                    fill: #ffffff;
                  }
                }

                p {
                  color: #ffffff;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                }
              }
              .social {
                .logos {
                  display: flex;
                  flex-direction: row;
                  gap: 23px;
                }
              }
            }
          }
        }
      }
    }
  }
  .section-8 {
    display: flex;
    justify-content: center;
    padding: 80px;
    .section-8-container {
      width: 60%;
      display: flex;
      flex-direction: column;
      // gap: 30px;
      align-items: center;
      .section-heading {
        font-size: 32px;
        font-weight: 400;
        line-height: 43.58px;
        margin-bottom: 30px;
        span {
          font-size: 32px;
          font-weight: 600;
          line-height: 43.58px;
          color: #e31e26;
        }
      }

      .arrows {
        display: flex;
        justify-content: flex-end;
        .reverse {
          transform: rotate(-180deg);
          margin-left: 10px;
        }
      }
      .client-reviews {
        width: 100%;
        display: flex;
        gap: 10px;
        overflow-x: scroll;
        overflow-y: unset;
        scroll-behavior: smooth;
        .testimonial-card {
          display: flex;
          min-width: 100%;

          border: 2px solid red;
          border-radius: 10px;
          padding: 20px;
          max-width: 600px;
          // margin: 20px auto;
          background: linear-gradient(
            to bottom left,
            #e31e268f,
            #ffffff 20%,
            #ffffff 80%,
            #e31e268f
          ); /* Gradient effect */
          // background: linear-gradient(to bottom right, #fff, #fbeff0);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          position: relative;

          .quote-icon {
            // position: absolute;
            color: red;
            font-size: 24px;

            &.top {
              top: 10px;
              left: 10px;
            }

            &.bottom {
              bottom: 10px;
              right: 10px;
            }
          }
          .bottom {
            transform: rotate(180deg);
          }
          .content {
            padding: 30px 10px;
          }
          .testimonial-text {
            font-size: 14px;
            font-weight: 600;
            line-height: 19.07px;
            letter-spacing: 0.03em;
            text-align: justified;
          }

          .author-info {
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 600;
            line-height: 19.07px;
            text-align: end;

            color: #e31e26;
            .author-company {
              color: #e31e26;
              font-style: italic;
            }
          }

          .rating-stars {
            text-align: center;
            margin-top: 20px;
            color: red;
            font-size: 20px;
          }
        }
      }
      .client-reviews::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .section-9 {
    display: flex;
    align-items: center;
    justify-content: center;
    .section-9-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      p {
        font-size: 232px;
        color: $--my-red;
        margin: 0;
      }
      .we-loading {
        width: 25%;
        height: 200px;
        margin-right: 20px;
        margin-bottom: 30px;
      }
      .text {
        h1 {
          color: $--my-black;
          font-size: 54px;
          margin: 0;
          text-transform: uppercase;
        }
      }
      .we-text-loading {
        width: 75%;
        height: 200px;
        margin-bottom: 30px;
      }
    }
  }
  .faq-section {
    width: 75%;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 100px;
    .faq-heading {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 43.58px;
      margin-bottom: 40px;

      .highlight {
        font-size: 32px;
        font-weight: 700;
        line-height: 43.58px;

        color: red; // Highlight 'Questions' in red
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .accordion-item {
        border: none; // Optional: Remove accordion item borders
      }

      .accordion-header {
        position: relative; // Ensure proper positioning for the icon
        font-weight: 500;

        // Completely override the default arrow icon
        .accordion-button {
          background: #f1f1f1;
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-radius: 0px !important;
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #202020;
        }
        .accordion-button::after {
          content: "+"; // Default icon
          font-size: 20px;
          background-image: unset !important;
          font-weight: bold;
          color: red;
          margin-left: 10px; // Space between text and icon
          transform: none !important; // Prevent Bootstrap's rotation
          transition: all 0.3s ease; // Smooth transition for icon change
        }

        // When the accordion item is expanded
        .accordion-button:not(.collapsed)::after {
          content: "-"; // Change icon to '-'
          font-size: 20px;
          font-weight: bold;
          color: red;
          background-image: unset !important;
        }
      }

      .accordion-body {
        padding: 15px 20px;
        background-color: #f8f8f8;
        font-size: 14px;
        color: #333;
      }
    }
  }
}
@media (max-width: 1600px) {
  .home-main {
    .logo-bg {
      height: 240px;
      right: 40px;
    }
  }
}
@media (max-width: 1100px) {
  .home-main {
    .section-1 {
      width: 82%;
      .section-1-container {
        // gap: 70px;
        .main-text {
          div {
            .my-svg {
              img {
                height: 150px;
              }
            }
          }
        }
      }
    }
    .section-3,
    .section-4,
    .section-5 {
      width: 82%;
    }
    .section-2 {
      width: 100%;
    }
    .logo-bg {
      height: 160px;
      width: 110vw;
    }
    .section-6,
    .section-milestones,
    .section-our-team,
    .section-our-founder {
      width: 82%;
      // .section-6-container {
      //     margin: 0 40px 0 0;
      // }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 210px;
        }
        .text {
          h1 {
            font-size: 48px;
          }
        }
      }
    }
  }
}
@media (max-width: 950px) {
  .home-main {
    .section-1 {
      margin-top: 0;
      .section-1-container {
        flex-direction: column;
        gap: 100px;

        .main-text {
          h1 {
            font-size: 42px;
          }
          .line-1 {
            .vertical-text {
              font-size: 9px;
              left: -56px;
              top: 50px;
            }
          }
          .line-2 {
            font-size: 36px;
          }
          // @keyframes businessTagLineAnimation {
          //   0% {
          //     width: 0px;
          //   }
          //   40% {
          //     width: 100px;
          //   }
          //   55% {
          //     width: 90px;
          //   }
          //   85% {
          //     width: 270px;
          //   }
          //   100% {
          //     width: 260px;
          //   }
          // }
          div {
            width: 420px;
            .my-svg {
              transform: translate(0%, -35%);
              img {
                height: 120px;
              }
            }
          }
          .gif {
            transform: translateX(-35%);
          }
        }
        .my-svg {
          justify-content: center;
          .animate-SVG {
            left: unset;
          }
          img {
            width: 300px;
          }
        }
        .statistics {
          flex-direction: row;
          justify-content: center;
        }
      }
    }
    .section-3,
    .section-1,
    .section-4,
    .section-5,
    .section-6,
    .section-milestones,
    .section-our-team,
    .section-our-founder {
      width: 90%;
      // .section-6-container {
      //     margin: 0 40px 0 0;
      // }
    }
    .section-4 {
      .section-4-container {
        gap: 20px;
        img {
          width: unset;
        }
      }
    }
    .section-milestones {
      flex-direction: column-reverse;
      margin: 50px auto;
      .left,
      .right {
        // min-height: 400px;
        width: 100%;
      }
    }
    .section-5 {
      .section-5-container {
        width: 100%;
        padding: 0 20px;
        margin: 0;
        gap: 12px;
        .header {
          h1 {
            width: unset;
            font-size: 42px;
            .vertical-text {
              font-size: 8px;
              top: 25px;
              left: -32px;
            }
          }
          .arrows {
            display: none;
          }
        }
        .categories-drop-down {
          display: flex;
        }
        .loading-categories-drop-down {
          height: 50px;
          width: 100%;
        }
        .product-container {
          .product-categories {
            display: none;
          }
        }
      }
    }
    .section-7 {
      // padding: 30px;
      .section-7-container {
        .connect-form {
          gap: 10px;
          padding-right: 20px;
          width: 90%;
          img {
            height: 300px;
            width: 300px;
          }
          form {
            .input-grp {
              gap: 12px;
            }
          }
        }
      }
    }
    .section-6 {
      margin: 80px auto 0px auto;
    }
    .section-why-us {
      margin-top: 40px;
    }
    .section-8 {
      padding: 20px;
      margin: 60px 0px;

      .section-8-container {
        width: 80%;
      }
    }
    .section-wrapper {
      .section-our-team {
        margin-top: 50px;
      }
    }
    .section-2,
    .section-our-founder {
      margin-top: 80px;
      .row-2 {
        margin-top: 40px;
      }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 180px;
        }
        .text {
          h1 {
            font-size: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .home-main {
    .section-why-us .conatiner {
      width: 90%;
    }
    .section-8 .section-8-container {
      width: 100%;
      gap: 20px;
      .section-heading {
        margin-bottom: 10px;
      }
    }
    .section-1 {
      width: 90%;
      .section-1-container {
        flex-direction: column;
        gap: 60px;
        .main-text {
          @keyframes businessTagLineAnimation {
            0% {
              width: 0px;
            }
            40% {
              width: 100px;
            }
            55% {
              width: 90px;
            }
            85% {
              width: 270px;
            }
            100% {
              width: 360px;
            }
          }
          div {
            width: 360px;
            .call-btn {
              margin-top: 20px;
            }
            .my-svg {
              transform: translate(0%, -35%);
            }
          }
          .gif {
            margin-top: 16px;
            transform: translateX(-50%);
          }
        }
        .my-svg {
          justify-content: center;
          .animate-SVG {
            left: unset;
          }
        }
        .statistics {
          flex-direction: row;
          gap: unset;
          justify-content: space-between;
          div {
            span {
              text-align: center;
            }
          }
          .loading-statistics-container {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
    .section-2 {
      margin-top: 80px;
    }

    .section-milestones {
      flex-direction: column-reverse;
      margin: 50px auto;
      .left {
        width: 100%;
        margin-top: 0px;
      }
      .right {
        // min-height: 400px;
        width: 100%;
      }
    }
    .section-our-founder {
      margin: 80px auto 40px;
      .row-1 {
        flex-direction: column-reverse;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
          iframe {
            width: 100%;
          }
        }
      }
      .row-2 {
        flex-direction: column-reverse;
        .left {
          width: 100%;
        }
        .right {
          justify-content: center;
          width: 100%;
          iframe {
            width: 100%;
          }
        }
      }
    }
    .section-2 {
      width: 100%;
      .section-2-container {
        .client-logo-container {
          justify-content: space-evenly;
        }
      }
    }
    .section-3,
    .section-6 {
      width: 90%;
    }
    .section-4 {
      width: 90%;
      margin: 80px auto 40px;
      .section-4-container {
        flex-direction: column;
        align-items: center;
        img {
          display: block;
          width: 100%;
        }
        .loading-about-us-img {
          display: none;
        }
        .content {
          .loading-about-us-content {
            // height: 300px;
            width: 100%;
          }
        }
      }
    }
    .logo-bg {
      height: 130px;
      margin-bottom: 50px;
    }
    .section-5 {
      width: 90%;
      // padding: 20px;
      // margin-top: 40px;
    }
    .section-6 {
      width: 90%;
      .section-6-container {
        // flex-direction: column;
        // width: unset;
        // padding: 0 30px;
        // margin-top: 80px;
        // margin-right: 0;
        flex-direction: column;
        gap: 0;

        img {
          width: 60%;
          // display: none;
        }
      }
    }
    .section-7 {
      // padding: 6px;
      padding-right: 0px;
      // margin: 50px 0;
      .section-7-container {
        .connect-form {
          padding: 6px;
          padding-right: 10px;
          gap: 6px;
          img {
            height: 240px;
            width: 240px;
          }
          form {
            .input-grp {
              gap: 8px;
              .call-grp {
                gap: 0px;
              }
            }
          }
        }
      }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 140px;
        }
        .text {
          h1 {
            font-size: 32px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .home-main {
    .faq-section {
      width: 100%;
      margin-bottom: 20px;
    }
    .section {
      margin: 40px 0px;
    }
    .section-1 {
      width: 100%;
      padding: 0 20px;
      .section-1-container {
        .main-text {
          gap: 0;
          h1 {
            font-size: 32px;
            line-height: 50px;
            margin: 0;
          }
          .line-1 {
            .vertical-text {
              font-size: 8px;
              left: -50px;
              top: 26px;
            }
          }
          .line-2 {
            font-size: 32px;
            margin: 0;
          }
          .slogan {
            margin-bottom: 25px;
          }
          @keyframes businessTagLineAnimation {
            0% {
              width: 0px;
            }
            40% {
              width: 85px;
            }
            55% {
              width: 65px;
            }
            85% {
              width: 210px;
            }
            100% {
              width: 340px;
            }
          }
          div {
            width: unset;
            .my-svg {
              display: none;
            }
          }
          p {
            width: unset;
            font-size: 12px;
            br {
              height: 10px;
            }
          }
          .gif {
            margin-top: 16px;
            transform: translateX(-12%);
          }
        }
      }
    }
    .section-2 {
      width: 100%;
      padding: 20px;
      margin: 50px auto;

      .section-2-container {
        width: 100%;
        .section-heading {
          flex-direction: column;
          font-size: 26px;
          span {
            font-size: 36px;
          }
        }
        h1 {
          font-size: 32px;
          line-height: 44px;
          .vertical-text {
            font-size: 12px;
            top: 23px;
            left: -44px;
          }
          .bold {
            max-width: 230px;
          }
        }
        .client-logo-container {
          display: none;
        }
        .client-logo-swiper {
          display: block;
          margin-top: 16px;
          width: 100%;
        }
      }
    }
    .section-milestones {
      margin: 0px auto;
      padding: 0 20px;
      gap: 0;
      width: 100%;
      .left {
        margin-top: 0;
        h2 {
          margin-bottom: 20px;
        }
        ul {
          li {
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
        }
      }
    }
    .section-wrapper {
      padding: 20px;
      .section-our-team {
        width: 100%;
        .heading-container {
          width: 150px;
          height: 40px;
          .heading {
            font-size: 20px;
          }
        }
        .team-detail-container {
          width: 100%;
          height: unset;
          .triangel {
            display: none;
          }
          .team-detail {
            padding: 10px;
            font-size: 12px;
            text-align: left;
            line-height: unset;
          }
        }
      }
    }
    .section-3 {
      width: 100%;
      margin: auto;
      .section-3-container {
        padding: 8px;
        h1 {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 32px;
          line-height: 44px;
          .vertical-text {
            font-size: 8px;
            top: 2px;
            left: -26px;
          }
          .bold {
            max-width: 180px;
          }
        }
        .stratergies {
          flex-direction: column;
          gap: 16px;
          width: unset;
          .stratergy {
            .title {
              display: flex;
              gap: 8px;
              p {
                font-size: 24px;
              }
              .red {
                font-size: 24px;
              }
            }
          }

          .loading-strat {
            height: 100px;
            width: 99%;
          }
        }
      }
    }
    .logo-bg {
      height: 100px;
      right: 25px;
      margin-top: 50px;
    }
    .section-4 {
      margin: 50px auto;
      width: 100%;
      .section-4-container {
        gap: 0px;
        padding: 0 20px;
        .content {
          h1 {
            font-size: 34px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .section-5 {
      width: 100%;
      .section-5-container {
        .header {
          h1 {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
    .section-6 {
      width: 100%;
      .section-6-container {
        padding: 20px;
        img {
          width: 70%;
        }
        .text-container {
          margin-left: 10px;
          h1 {
            font-size: 32px;
            .vertical-text {
              font-size: 12px;
              top: 32px;
              left: -32px;
            }
            .bold {
              max-width: 170px;
            }
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .section-7 {
      padding: 0;
      .section-7-container {
        // background-color: #000;
        padding: 20px;
        .section-heading {
          color: white;
          align-self: flex-start;
        }
        .connect-form {
          .contact-detail {
            flex-direction: column;
            gap: 30px;
            .left {
              width: 100%;
            }
            .right {
              width: 100%;
              justify-content: flex-start;
            }
          }

          img {
            display: none;
          }
        }
      }
    }
    .section-8 {
      margin: 0;
    }
    .section-our-founder {
      margin-top: 30px;
      .row-1 .left .footer {
        flex-direction: column;
        gap: 20px;
        text-align: start;
      }
      .left,
      .right {
        h5 {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .founder-detail {
          p {
            font-size: 14px !important;
          }
          .p-2,
          .p-3 {
            margin-top: 0px;
          }
        }
      }
    }
    .section-why-us .conatiner {
      .heading {
        margin-bottom: 15px;
        font-size: 28px;
      }
      .detail-container {
        gap: 30px;
        .icon-wrapper {
          gap: 0;
          svg {
            width: 50px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
    .section-8 {
      .section-8-container {
        .client-reviews {
          .review {
            gap: 12px;
            .text {
              font-size: 12px;
              .company-name {
                font-size: 10px;
                color: $--secondary-font-color;
              }
            }
          }
        }
      }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 80px;
        }
        .text {
          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .home-main {
    .section-milestones {
      margin-top: -50px;

      .left {
        margin-top: -60px;
      }
    }
    .section-our-team {
      img {
        height: 460px;
      }
    }
  }
}
@media (max-width: 450px) {
  .home-main {
    .section-milestones {
      margin-top: -90px;

      .left {
        margin-top: -60px;
      }
    }
    .section-our-team {
      img {
        height: 390px;
      }
      .team-detail-container {
        bottom: -40px;
      }
    }
  }
}
@media (max-width: 400px) {
  .home-main {
    .section-1 {
      padding: 0 0 0 20px;
      .section-1-container {
        .main-text {
          gap: 0;
          h1 {
            font-size: 32px;
            line-height: 50px;
            margin: 0;
          }
          .line-1 {
            .vertical-text {
              font-size: 8px;
              left: -50px;
              top: 26px;
            }
          }
          div {
            .my-svg {
              display: none;
            }
          }
          p {
            width: unset;
          }
          .gif {
            margin-top: 16px;
            transform: translateX(-12%);
          }
        }
      }
    }
    .section-milestones {
      margin-top: -100px;
      .left {
        margin-top: -90px;
      }
    }
    .section-2 {
      .section-2-container {
        .section-heading {
          gap: 0px;
          font-size: 24px;
          span {
            font-size: 32px;
          }
        }
      }
    }

    .logo-bg {
      height: 70px;
      margin-bottom: 0;
    }
    .section-6 {
      .section-6-container {
        padding: 0;
        .text-container {
          h1 {
            font-size: 28px;
            margin-left: 30px;
            .vertical-text {
              top: 26px;
              left: -30px;
              font-size: 12px;
            }
            .bold {
              max-width: 150px;
            }
          }
          p {
            padding: 0 20px;
          }
          .call-btn {
            margin-left: 20px;
          }
        }
      }
    }
    .section-7 {
      .section-7-container {
        h1 {
          font-size: 34px;
          .vertical-text {
            top: 12px;
          }
        }
        .connect-form {
          width: 100%;

          form {
            width: 100%;
            gap: 8px;
            .input-grp {
              flex-direction: column;
              .input {
                width: 100%;
              }
              input {
                width: 100%;
              }
              .call-grp {
                width: 100%;
              }
            }
            button {
              margin-top: 24px;
            }
          }
        }
      }
    }
    .faq-section .accordion .accordion-header .accordion-button {
      height: 85px;
    }
    .section-8 {
      padding: 12px;
      margin: 80px 0px;
      .section-8-container {
        .client-reviews {
          .review {
            gap: 8px;
          }
        }
      }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 70px;
        }
        .text {
          h1 {
            font-size: 16px;
          }
        }
      }
    }
    .section-wrapper {
      .section-our-team {
        margin-bottom: 80px;
        img {
          height: 340px;
        }
        .heading-container {
          top: 10px;
          width: 120px;
          height: 30px;
          .heading {
            font-size: 16px;
          }
        }
        .team-detail-container {
          bottom: -80px;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .home-main {
    .section-1 {
      padding: 0;
      .section-1-container {
        .main-text {
          padding-left: 20px;
          h1 {
            font-size: 28px;
            line-height: 38px;
            margin: 0;
          }
          .line-1 {
            .vertical-text {
              font-size: 10px;
              left: -60px;
              top: 36px;
            }
          }
          .line-2 {
            font-size: 28px;
          }
          @keyframes businessTagLineAnimation {
            0% {
              width: 0px;
            }
            40% {
              width: 70px;
            }
            55% {
              width: 60px;
            }
            85% {
              width: 185px;
            }
            100% {
              width: 175px;
            }
          }
        }
        .statistics {
          div {
            .number {
              font-size: 36px;
            }
            .text {
              font-size: 12px;
            }
          }
        }
      }
    }
    .section-2 {
      .section-2-container {
        h1 {
          font-size: 28px;
          margin-left: 20px;
        }
      }
    }
    .section-9 {
      .section-9-container {
        p {
          font-size: 60px;
        }
        .text {
          h1 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
