.loading-blog-card {
 max-width: 450px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  box-shadow: 0px 3px 11px 0px #0000001a;
  border-radius: 24px;
  div {
    width: 100%;
    height: 227px;
    border-radius: 24px;
  }

  @media (max-width: 1020px) {
    max-width: 650px;
  }
}
