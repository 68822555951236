.faq-section {
    width: 75%;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 100px;
    .faq-heading {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 43.58px;
      margin-bottom: 40px;

      .highlight {
        font-size: 32px;
        font-weight: 700;
        line-height: 43.58px;
        color: red; // Highlight 'Questions' in red
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .accordion-item {
        border: none; // Optional: Remove accordion item borders
      }

      .accordion-header {
        position: relative; // Ensure proper positioning for the icon
        font-weight: 500;

        // Completely override the default arrow icon
        .accordion-button {
          background: #f1f1f1;
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-radius: 0px !important;
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #202020;
        }
        .accordion-button::after {
          content: "+"; // Default icon
          font-size: 20px;
          background-image: unset !important;
          font-weight: bold;
          color: red;
          margin-left: 10px; // Space between text and icon
          transform: none !important; // Prevent Bootstrap's rotation
          transition: all 0.3s ease; // Smooth transition for icon change
        }

        // When the accordion item is expanded
        .accordion-button:not(.collapsed)::after {
          content: "-"; // Change icon to '-'
          font-size: 20px;
          font-weight: bold;
          color: red;
          background-image: unset !important;
        }
      }

      .accordion-body {
        padding: 15px 20px;
        background-color: #f8f8f8;
        font-size: 14px;
        color: #333;
      }
    }
  }