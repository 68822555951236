@import "/src/variables";
@import "/src/index.scss";

.dashboard-main {
    .dashboard-body {
        .main-content {
            .edit-profile-main {
                border-radius: var(--spacing-1, 8px);
                border: 1px solid #d0d5dd;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
                    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
                background: $--background-color;
                color: $--my-black;
                padding: 24px;
                display: flex;
                flex-direction: column;
                gap: 24px;

                .heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h1 {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
                
                .user-info {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    .user-img {
                        position: relative;
                        display: inline-block;
                        height: 100px;
                        width: 100px;
                        border-radius: 50%;
                        background-color: #dddddd;
                        cursor: pointer;
                        
                        .edit-image {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                            background-color: #FFFFFF44;
                            
                            display: none;
                            place-items: center;
                        }
                        img{
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                        }
                    }

                    .user-img:hover .edit-image {
                        display: grid;
                    }

                    .user-name * {
                        margin: 0;

                        .name {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 34px;
                        }

                        .about {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                form {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .group {
                        display: flex;
                        gap: 24px;
                        width: 100%;
                        .form-group {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            width: 100%;

                            label {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;

                                span {
                                    color: #e62e2e;
                                }
                            }
                            input, select {
                                padding: 12px 16px;
                                background-color: white;
                                color: $--secondary-font-color;
                                border: 1px solid #d0d5dd;
                                border-radius: 4px;
                                outline: none;
                            }
                            input{
                                width: 100%;
                            }
                            
                            .call-grp {
                                display: flex;
                                select {
                                    border-right: none;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                }
                                input {
                                    border-left: none;
                                    border-top-left-radius: 0px;
                                    border-bottom-left-radius: 0px;
                                }
                            }

                            p {
                                align-self: flex-end;
                                margin: 0;
                                color: #667085;
                                font-size: 12px;
                                font-weight: 400;
                                cursor: pointer;
                            }
                        }
                    }

                    .btn-red {
                        width: 170px;
                        padding: 10px 24px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 580px) {
    .dashboard-main {
        .dashboard-body {
            .main-content {
              .edit-profile-main {
                  padding: 12px;
                    .heading {
                      button{
                        padding: 10px;
                      }
                    }
                    form {
                        .group {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}
