@import "/src/variables";
@import "/src/index.scss";
.section-tank-you{
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width:500px;
        width: 100%;
        text-align: center;
        height: 70vh;
        
        h2{
            color: #e31e26;
            margin-top: 10px;
        }
        p{
           color: #7D7C7D;
           font-size: 14px;
        }
        .call-btn-wrapper {
            display: flex;
            justify-content: center;
            // width: 420px;
            width: min(100%, 420px);
      
            z-index: 0;
      
            .call-btn {
              display: flex;
              align-items: center;
              position: relative;
          
              .primary-btn {
                width: 130px;
                border: 1px solid var(--color-brand-logo-1, #e31e26);
                box-shadow: 0px 0px 4px 1px #e31e26 inset;
                transition: all 0.18s ease-in;
                text-wrap: nowrap;
                &:before {
                  transition: all 0.18s ease-in;
                }
              }
              .call-icon {
                background-color: $--my-red;
                padding: 8px;
                display: inline-grid;
                place-items: center;
                border-radius: 50%;
                position: absolute;
                transition: all 0.18s ease-in 0s;
                left: 0;
                z-index: -1;
                svg {
                  path {
                    fill: #ffffff;
                  }
                }
              }
            }
            .call-btn:hover > .call-icon {
              left: calc(100% + 8px);
            }
            .my-svg {
              position: relative;
              // transform: translate(90%, -50%);
              img {
                // position: absolute;
              }
              .animate-SVG {
                position: absolute;
                left: 0;
                animation: svgAnimation 5.5s ease 0s infinite;
              }
              @keyframes svgAnimation {
                0% {
                  transform: rotate(0deg);
                }
                20% {
                  transform: rotate(0deg);
                }
                30% {
                  transform: rotate(-5deg);
                }
                50% {
                  transform: rotate(-5deg);
                }
                60% {
                  transform: rotate(-15deg);
                }
                80% {
                  transform: rotate(-15deg);
                }
                100% {
                  transform: rotate(0deg);
                }
              }
            }
          }
    }
   
}