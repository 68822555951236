@import "/src/variables";
@import "/src/index.scss";

.refund-policy-main {
    display: flex;
    flex-direction: column;
    padding: 60px 110px;
    h1 {
        color: $--my-black;
        font-size: 40px;
        font-weight: 600;
        text-transform: capitalize;
    }
    p {
        color: $--secondary-font-color;
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width: 1100px){
    .refund-policy-main{
        padding: 40px;
    }
}
@media (max-width: 800px){
    .refund-policy-main{
        padding: 30px;
    }
}
@media (max-width: 650px){
    .refund-policy-main{
        padding: 40px 20px;
    }
}