@import "/src/variables";
@import "/src/index.scss";

.dashboard-main {
    font-family: "poppins";
    color: $--my-black;
    margin-top: -105px;

    .header-main {
        display: none;
    }

    .dashboard-body {
        display: flex;
        height: 100%;

        .side-panel {
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: fixed;
            height: 100%;
            width: fit-content;
            max-width: 285px;
            background-color: $--background-color;
            padding: 16px;
            border-right: 1px solid var(--gray-200, #eaecf0);

            svg{
                cursor: pointer;
            }
            .profile {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                gap: 12px;

                .profile-info {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    img {
                        height: var(--spacing-5, 35px);
                        width: var(--spacing-5, 35px);
                        border-radius: 50%;
                    }
                    .details {
                        p {
                            margin: 0;
                            color: $--my-black;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .bold {
                            font-weight: 600;
                        }
                    }
                }

                .edit {
                    height: 20px;
                    width: 20px;
                }
            }

            .items {
                display: flex;
                flex-direction: column;
                gap: 12px;
                color: $--my-black;

                .item-container {
                    padding: 10px 24px;
                    border-radius: 6px;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                    cursor: pointer;

                    .item-icon {
                        margin-right: 16px;

                        svg {
                            height: 20px !important;
                            width: 20px !important;

                            path {
                                stroke: $--my-black;
                            }
                        }
                    }

                    .no-strke {
                        svg {
                            path {
                                stroke: unset;
                            }
                        }
                    }
                }

                .current {
                    font-weight: 700;
                    background-color: $--my-red;
                    color: white;

                    .item-icon {
                        svg {
                            path {
                                fill: $--my-red;
                                stroke: white;
                            }
                        }
                    }
                    .logout {
                        svg {
                            path {
                                fill: white;
                            }
                        }
                    }
                }

                .btn-red {
                    padding: 10px 20px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $--my-black;
                }
            }
        }

        .main-content {
            width: 100vw;
            padding: 30px 50px 50px 290px;

            .route {
                display: flex;
                align-items: center;
                gap: 4px;
                color: $--secondary-font-color;
                margin: 4px;
                a,
                p {
                    color: $--secondary-font-color !important;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .dashboard-main {
        .header-main {
            display: flex;
            .menu {
                display: none;
            }
            .mobile-icons {
                display: flex;
                gap: 12px;

                img {
                    height: 28px;
                    width: 28px;
                }
            }
        }
        .dashboard-nav {
            display: none;
        }

        .dashboard-body {
            margin-top: 185px;
            .side-panel {
                display: none;
            }

            .main-content {
                padding: 20px !important;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .dashboard-main {
        .dashboard-nav-mobile {
            .menu {
                svg {
                    height: 24px !important;
                    width: 24px !important;
                }
            }

            .dashboard {
                gap: 0px;

                .logo {
                    svg {
                        width: 100px;
                    }
                }
                .hr {
                    margin-right: 14px;
                }
            }

            .profile-icon {
                height: 24px;
                width: 24px;
            }
        }
    }
}
