.blog-detail {
  .wrapper {
    .section-banner {
      position: relative;
      height: 306px;
      background: #e31e261a;
      display: flex;
      justify-content: center;
      align-items: center;
      .bg-image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }
      .bg-left {
        position: absolute;
        left: 0;
        top: 0;
        height: 50%;
        z-index: -1;
      }
      .bg-right {
        position: absolute;
        right: 0;
        height: 50%;
        bottom: 0;
        z-index: -1;
      }
      .content-wrapper {
        position: relative;

        padding: 0 40px;
        max-width: 988px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
        .component-loading {
          height: 150px;
          width: 100%;
          border-radius: 24px;
        }

        .title {
          width: 100%;
          font-weight: 700;
          color: #202020;
        }
        .author-detail {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            p {
              margin: 0;
              font-weight: 700;
              color: #e31e26;
            }
          }
          .publish-detail {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            p {
              margin: 0;
            }
            .date,
            .time {
              font-weight: 600;
              color: #202020;
              display: flex;
              align-items: center;

              gap: 10px;
              svg {
                fill: #e31e26;
              }
            }
          }
        }
      }
    }
    .section-blog-detail {
      margin: 100px 50px 50px;
      .content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 50px;
        .left {
          width: 30%;
          max-width: 310px;
          position: sticky;
          top: 100px;
          overflow: hidden;
          background: white;
          border-radius: 24px;
          .table-web {
            display: block;
          }
          .table-mobile {
            display: none;
            padding: 0px !important;
            margin-bottom: 0px !important;
            box-shadow: 0px 3px 11px 0px #0000001a;

            .accordion {
              border-radius: 24px;
              box-shadow: 0px 3px 11px 0px #0000001a;

              .accordion-item {
                border-radius: 24px;

                .accordion-header {
                  border-radius: 24px;
                  .accordion-button:not(.collapsed) {
                    background-color: #fdeaea;
                  }
                  .accordion-button:focus {
                    box-shadow: none !important;
                  }
                  button {
                    border-radius: 24px;
                    height: 56px;
                    padding: 20px;
                    .heading {
                      margin-bottom: 0px !important;
                    }
                  }
                }
              }
            }
          }

          .table-of-content {
            padding: 20px 20px;
            box-shadow: 0px 3px 11px 0px #0000001a;
            border-radius: 24px;
            margin-bottom: 20px;
            .heading {
              font-weight: 700;
              line-height: 21.79px;
              color: #e31e26;
              margin-bottom: 20px;
            }
            .content {
              ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-left: 15px;
                .component-loading {
                  height: 20px;
                  border-radius: 24px;
                }
                li {
                  // list-style: none;
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 16.34px;
                  div {
                    cursor: pointer;
                  }
                }
                // li::marker {
                //   color: #e31e26;
                // }
              }
            }
          }
          .call-to-action {
            padding: 20px;
            box-shadow: 0px 3px 11px 0px #0000001a;
            border-radius: 24px;
            margin-bottom: 50px;
            background: #e31e26;

            .heading {
              font-size: 16px;
              font-weight: 700;
              line-height: 21.79px;
              color: white;
              margin-bottom: 20px;
            }
            form {
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              input,
              textarea {
                border: 1px solid #ffffff;
                width: Fill (439.28px) px;
                height: 30px;
                padding: 10px;
                font-size: 12px;
                gap: 10px;
                border-radius: 4px;
                background: transparent;
                color: #ffffff;
              }
              input::placeholder,
              textarea::placeholder {
                color: #ffffff80;
              }
              input:focus,
              textarea:focus {
                border-color: white;
              }
              .input-grp {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                input {
                  width: 100%;
                  font-size: 12px;
                }
                .input {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  gap: 5px;
                  label {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                  }
                }

                .call-grp {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  label {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                    margin-bottom: 10px;
                  }

                  input {
                    border: none;
                    width: 100%;
                  }
                  .input {
                    width: 100%;
                    flex-direction: row;
                    gap: 8px;
                    border: 1px solid #ffffff;
                    border-radius: 4px;
                    padding-left: 10px;
                  }
                  select {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: white;
                    font-size: 12px;
                    option {
                      color: black;
                    }
                  }
                  .country-code {
                    position: relative;
                    p {
                      margin: 0;
                    }
                    .selected-code {
                      display: flex;
                      gap: 8px;
                      align-items: center;

                      p {
                        color: white;
                        font-size: 16px;
                      }
                      img {
                        height: 16px;
                        width: 16px;
                      }
                    }
                    .options {
                      display: none;
                      position: absolute;
                      color: #c6c6c6;
                      z-index: 2;
                      p {
                        margin: 2px 0;
                        cursor: pointer;
                      }
                    }
                  }
                  .open {
                    .selected-code {
                      img {
                        transform: rotate(180deg);
                      }
                    }
                    .options {
                      display: block;
                    }
                  }
                }
              }
              button {
                border-radius: 24px;
                height: 30px;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: #e31e26;
                border: none;
                margin-top: 20px;
                // background: #ffffff;
              }
              button:hover {
                background: rgb(253 234 234);
              }
            }
          }
        }
        .right {
          width: 70%;
          a {
            text-decoration: underline !important;
            color: blue;
          }
          .detail-wrapper {
            padding: 20px;
            border-radius: 24px;
            box-shadow: 0px 3px 11px 0px #0000001a;
            display: flex;
            flex-direction: column;
            gap: 0px;
            .component-loading {
              border-radius: 24px;
              margin-bottom: 20px;
            }
            .blog-section {
              .heading {
                font-weight: 700;
                margin-bottom: 10px;
                margin-top: 10px;
              }
              .highlighted-content {
                margin-top: 10px;
                border: 1px solid #e31e26;
                background: #e31e260d;
                padding: 20px;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                border-radius: 10px;
                margin-bottom: 10px;
                p {
                  margin-bottom: 0;
                }
              }
              .content {
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                color: #212529;
                img {
                  max-width: 100%;
                  margin: 30px 0px 10px;
                  border-radius: 10px;
                }
                // margin-left: 20px;
                ul {
                  margin-top: 10px;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  li {
                    line-height: normal;

                    span {
                      font-size: 14px;
                      font-weight: 700;
                      line-height: normal;
                      color: #e31e26;
                    }
                  }
                  li::marker {
                    color: #e31e26;
                  }
                }
              }
              .pointer-wrapper {
                margin-top: 20px;
                margin-bottom: 10px;
                .pointer {
                  .heading {
                    color: #e31e26;
                    // font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                  }
                  .content {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: normal;
                    .example-container {
                      .heading {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: normal;
                        color: #e31e26;
                        margin-top: 10px;
                        margin-bottom: 0px;
                      }
                      .content {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .author-detail-wrapper {
        position: relative;
        height: 250px;
        border-radius: 24px;
        width: fit-content;
        padding: 0px 50px;
        background: #e31e261a;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        .bg-left {
          position: absolute;
          left: 0;
          top: 0;
          height: 70%;
          border-radius: 24px 0px 0px 0px;
        }
        .bg-right {
          position: absolute;
          right: 0;
          height: 60%;
          bottom: 0;
          border-radius: 0px 0px 24px 0px;
          z-index: -1;
        }
        .content-wrapper {
          padding: 0 40px;
          max-width: 878px;
          width: 100%;
          margin: 0;

          display: flex;
          justify-content: center;
          align-items: center;
          .profile-img {
            img {
              width: 150px;
              height: 150px;
              border-radius: 50%;
            }
          }
          .author-detail {
            .name {
              // font-size: 16px;
              font-weight: 700;
              // line-height: 21.79px;
              color: #e31e26;
              margin-bottom: 20px;
            }
            .detail {
              font-size: 12px;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .blog-detail {
    .wrapper {
      .section-banner {
        height: unset;
        padding: 40px 0px;

        .bg-image {
        }
        .bg-left {
        }
        .bg-right {
        }
        .content-wrapper {
          .title {
          }
          .author-detail {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            .name {
              img {
              }
              p {
              }
            }
            .publish-detail {
              p {
              }
              .date,
              .time {
                font-size: 14px;

                svg {
                }
              }
            }
          }
        }
      }
      .section-blog-detail {
        margin: 50px 20px;

        .content-wrapper {
          flex-direction: column;

          .left {
            width: 100%;
            max-width: 100%;
            .table-web {
              display: none;
            }
            .table-mobile {
              display: block;
            }
            .table-of-content {
              max-width: 100%;
              .heading {
              }
              .content {
                padding: 0;
                ul {
                  li {
                  }
                }
              }
            }
            .call-to-action {
              display: none;
              .heading {
              }
            }
          }
          .right {
            width: 100%;
            .detail-wrapper {
              gap: 10px;
              .blog-section {
                .heading {
                }
                .highlighted-content {
                }
                .content {
                  ul {
                    li {
                      span {
                      }
                    }
                    li::marker {
                    }
                  }
                }
                .pointer-wrapper {
                  .pointer {
                    .heading {
                    }
                    .content {
                      .example-container {
                        .heading {
                        }
                        .content {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .author-detail-wrapper {
          height: unset;
          .bg-left {
            z-index: -1;
            width: unset;
            height: 50%;
          }
          .bg-right {
            z-index: -1;
            width: unset;
            height: 50%;
          }
          .content-wrapper {
            padding: 40px 20px;
            .profile-img {
              img {
              }
            }
            .author-detail {
              .name {
              }
              .detail {
              }
            }
          }
        }
      }
    }
  }
}
