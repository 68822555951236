@import "/src/variables";
@import "/src/index.scss";

.cart-page {
    display: flex;
    flex-direction: column;
    padding: 60px 110px;

    .cart-main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: var(--spacing-3, 24px);
        border-radius: 6px;
        border: 1px solid var(--color-outline-outline-2, #c7c7cd);

        hr {
            margin: 0;
        }
        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                color: $--my-black;
                font-size: 40px;
                font-weight: 600;
                margin: 0;
            }
            p {
                color: $--my-black;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
        }
        .cart-items {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .product-info {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .product-name {
                    display: flex;
                    justify-content: space-between;
                    gap: 12px;
                    img {
                        height: 50px;
                        width: 50px;
                        border: 1px solid $--secondary-font-color;
                    }
                    h3 {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 600;
                        width: 120px;
                    }
                }
                .price {
                    p {
                        color: $--my-black;
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    .remove {
                        text-align: right;
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        .coupon-code{
            align-self: flex-end;
            .input-grp{
                display: flex;
                gap: 12px;
                input{
                    padding: 12px var(--spacing-2, 16px);
                    border-radius: var(--spacing-0, 4px);
                    border: 1px solid #D0D5DD;
                    background: var(--white, #FFF);
                    outline: none;
                    max-width: 200px;
                }
            }
        }
        .total {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            p {
                color: $--my-black;
                font-size: 18px;
                font-weight: 700;
                margin: 0;
            }
            .old-ammount{
                color: $--secondary-font-color;
                text-decoration: line-through;
                font-size: 14px;
            }
        }
        .primary {
            width: fit-content;
            align-self: flex-end;
        }
    }
    .recommendation-services {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h3 {
            margin: 0;
            font-size: 40px;
            font-weight: 600;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: $--secondary-font-color;
        }
    }
    .loader{
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: #00000080;
        display: grid;
        place-content: center;
        z-index: 2;
    }
}

@media (max-width: 1100px) {
    .cart-page {
        padding: 30px 90px;
    }
}
@media (max-width: 850px) {
    .cart-page {
        padding: 30px;
    }
}
@media (max-width: 650px) {
    .cart-page {
        padding: 20px;
    }
}
@media (max-width: 450px) {
    .cart-page {
        .cart-main {
            .cart-items {
                .product-info {
                    flex-direction: column;
                    .quantity-price {
                        align-self: flex-end;
                    }
                }
            }
        }
        .recommendation-services {
            h3 {
                font-size: 32px;
            }
        }
    }
}
