@import "/src/variables";
@import "/src/index.scss";

.feedback-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   gap: 60px;

  .heading {
    padding: 20px 100px;
    max-width: 800px;
    h1 {
      margin-bottom: 20px;
      color: $--my-black;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      span {
        color: #e31e26;
      }
    }
    p {
      //   margin: 0;
      color: $--secondary-font-color;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      ul {
        width: 500px;
        li {
          text-align: start;
        }
      }

      strong {
        color: #e31e26;
        font-size: 16px;
      }
      // color: #e31e26;
      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .content {
    display: flex;
    gap: 30px;
    padding: 0px 20px 60px 20px;
    width: min(500px, 100%);

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 48px;
      .bottom-content {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }
      .heading {
        padding: 0;
        h2 {
          font-size: 36px;
          color: #10182b;
          font-weight: 600;
          text-align: left;
        }
        p {
          color: #475467;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          text-align: left;
        }
      }
      .inputs {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-3, 24px);
        width: 100%;
        h6 {
          margin-top: 20px;
          margin-bottom: 0px;
          font-weight: 600;
        }
        .input-grp {
          display: flex;
          flex-direction: column;
          label,
          p {
            margin: 0;
          }
          label {
            color: #344054;
            font-size: 14px;
            font-weight: 600;
            span {
              color: #e31e26;
            }
          }
          .emoji-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: 20px;
            margin-top: 5px;
            margin-bottom: 5px;
            .active {
              .button {
                scale: 1.4;
                transition: all ease-in 200ms;
                background: #c1c1c1;
              }
            }
            .button {
              cursor: pointer;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              background: #c1c1c175;
              border: none;
              font-size: 20px;
              transition: all ease-out 200ms;

              &:hover {
                scale: 1.4;
                transition: all ease-in 200ms;
              }
            }
          }
          input,
          textarea {
            padding: 12px var(--spacing-2, 16px);
            border-radius: var(--spacing-0, 4px);
            border: 1px solid #d0d5dd;
            background: var(--white, #fff);
            outline: none;
          }
          textarea {
            height: 130px;
            resize: none;
          }
        }
        .contect-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .privacy-check {
          display: flex;
          flex-direction: column;

          .check-box-container {
            display: flex;
            gap: 4px;
            align-items: center;
            .check-box {
              cursor: pointer;
              display: inline-block;
              height: 16px;
              width: 16px;
              border: 1px solid $--my-black;
              border-radius: 4px;
              display: grid;
              place-items: center;
            }
            .checked {
              background-color: $--my-black;
              svg {
                path {
                  fill: #fff;
                }
              }
            }
            p {
              margin: 0;
              color: #475467;
              font-size: 16px;
              font-weight: 400;

              a {
                color: #475467;
              }
            }
          }
        }
      }
    }
  }
  .feedback-info {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    background: #f9fafb;
    padding: 60px 0;
    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      a,
      p {
        text-decoration: none;
        margin: 0;
        font-size: 16px;
        text-align: center;
        color: #475467;
      }
      svg {
        path {
          fill: $--my-red;
        }
      }
      .title {
        font-size: 24px;
        color: #101828;
      }
      .text {
        font-weight: 400;
      }
      .office {
        max-width: 350px;
      }
    }
  }
}

@media (max-width: 900px) {
  .feedback-main {
    .heading {
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p ul{
        max-width: 500px;
        width: 100%;
      }
    }
  }
}
@media (max-width: 750px) {
  .feedback-main {
    gap: 30px;
    .content {
      flex-direction: column;
      padding: 0 20px;
      img,
      form {
        width: 100%;
      }
    }
  }
}
@media (max-width: 600px) {
  .feedback-main {
    gap: 30px;
    .feedback-info {
      flex-direction: column;
      align-items: center;
      padding: 30px;
    }
  }
}

.tooltip-inner {
  font-size: 12px !important;
}
