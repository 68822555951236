.modal-content{
    height: fit-content;
    width: fit-content;
    margin: auto;
    .modal-body{
        padding: 0 !important;
        max-height: 80vh;
        overflow-y: scroll;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #828282;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #4b4a4a;
    }
}
.modal-dialog{
    max-width: unset;
}