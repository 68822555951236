@import "/src/variables";
@import "/src/index.scss";

.case-study-main {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 60px 110px;
    .section-1 {
        p {
            color: $--my-red;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
        }
        h1 {
            color: $--my-black;
            font-size: 40px;
            font-weight: 600;
        }
        .description {
            color: $--secondary-font-color;
            font-weight: 400;
        }
    }
    .section-2 {
        display: flex;
        gap: 48px;
        .part-1 {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            gap: 48px;
            width: 50%;

            .text-container {
                h2 {
                    color: $--my-black;
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: capitalize;
                }
                li {
                    color: $--secondary-font-color;
                }
                p,
                li {
                    color: $--secondary-font-color;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
        .part-2 {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            gap: 32px;
            width: 50%;

            .case-study-img {
                // height: 100%;
                width: 100%;
            }
            .details {
                display: flex;
                justify-content: space-between;
                gap: 32px;
                span {
                    p {
                        margin-bottom: 12px;
                        font-size: 16px;
                    }
                    .title {
                        color: $--my-black;
                        font-weight: 600;
                    }
                    .description {
                        color: $--secondary-font-color;
                        font-weight: 400;
                        text-transform: capitalize;
                    }
                }
                .social {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 12px;
                    .logos {
                        display: flex;
                        gap: 12px;

                        span {
                            border-radius: 50%;
                            height: 42px;
                            width: 42px;
                            display: grid;
                            place-items: center;
                            cursor: pointer;
                            svg {
                                height: 20px;
                                width: 20px;
                                path {
                                    fill: white;
                                }
                            }
                        }
                        .insta {
                            background: #ea3e27;
                        }
                        .facebook {
                            background: #1877f2;
                        }
                        .linkedin {
                            background: #2867b2;
                        }
                        .twitter {
                            background: #1da1f2;
                        }
                        .whatsapp {
                            background: #60d66a;
                        }
                    }
                    .copy {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 10px 18px;
                        border-radius: 8px;
                        border: 1px solid #c7c7cd;
                        cursor: pointer;
                        p {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1100px) {
    .case-study-main {
        padding: 60px 55px;
    }
}
@media (max-width: 950px) {
    .case-study-main {
        padding: 60px 40px;
        .section-2 {
            flex-direction: column-reverse;
            .part-1 {
                width: 100%;
            }
            .part-2 {
                width: 100%;
            }
        }
    }
}
@media (max-width: 550px) {
    .case-study-main {
        padding: 60px 20px;
    }
}
@media (max-width: 400px) {
    .case-study-main {
        .section-2 {
            .part-2 {
                .details {
                    flex-direction: column;
                    gap: 0px;
                    .social {
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}
